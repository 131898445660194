import React from "react";
import { populateZipFromGeo } from "../../actions";
import { ActionLinkWithIcon } from "../../components";
import { MarginedBox } from "../../styledComponents";
import {
  TextInput,
  Select,
  SelectWrapper,
  Button
} from "../../styledComponents";

const ZipcodeUI = ({
  updateLocationOptions,
  zipcode,
  handleZipInput,
  geoEnabled,
  errorGeoLocation,
  radiusProp,
  title,
  radius,
  setRadius,
  geoLocation,
  setGeoLocation,
  setCleanedZipcode,
  setZipcode,
  setErrorGeolocation
}) => {
  let radiusValues = radiusProp.split(",");
  const options = radiusValues.map(val => (
    <option key={val} value={val}>
      {val}
    </option>
  ));

  const geoLocationButtonUI = () => {
    if (!geoEnabled) {
      return null;
    }
    return errorGeoLocation ? (
      <div role="alert">{errorGeoLocation}</div>
    ) : (
      <div>
        <div className="link-container">
          <MarginedBox>
            <ActionLinkWithIcon
              text="Use my current location"
              action={() => {
                populateZipFromGeo({
                  geoLocation,
                  setGeoLocation,
                  setCleanedZipcode,
                  setZipcode,
                  setErrorGeolocation
                });
              }}
            >
              <i class="fa-regular fa-location-dot"></i>
            </ActionLinkWithIcon>
          </MarginedBox>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={updateLocationOptions}>
      <h3>{title}</h3>
      <div className="inner">
        <span>Within</span>
        <SelectWrapper>
          <label className="a11y-hidden" htmlFor="zipcode-ui-radius">
            Choose a radius value
          </label>
          <Select
            id="zipcode-ui-radius"
            value={radius || radiusProp[0]}
            onChange={e => setRadius(e.target.value)}
          >
            {options}
          </Select>
          <i class="fa-light fa-chevron-down"></i>
        </SelectWrapper>
        <span>miles of</span>
        <div className="text-input">
          <label className="a11y-hidden" htmlFor="zipcode-ui-zipcode">
            enter your zip code
          </label>
          <TextInput
            id="zipcode-ui-zipcode"
            value={zipcode}
            onChange={handleZipInput}
            type="search"
            placeholder="Zip Code"
          />
        </div>
        <Button type="submit">go</Button>
      </div>
      <div>{geoLocationButtonUI()}</div>
    </form>
  );
};

export default ZipcodeUI;
