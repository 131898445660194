import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { ErrorContainer } from "../../styledComponents";

const DateInput = ({
  id,
  name,
  showErrors,
  setShowErrors,
  setValidDate,
  validDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const errors = {
    invalidRange: "Date out of range",
    invalidDate: "Invalid Date",
  };

  useEffect(() => {
    const newDate = dayjs(validDate[name], "MM/DD/YY").toDate();
    if (validDate[name]) {
      setSelectedDate(newDate);
    }
  }, [validDate, name]);

  const handleValidation = (date) => {
    const day = dayjs(date).startOf('day');
    const today = dayjs().startOf('day');
    const isSameOrAfter = day.isSame(today) || day.isAfter(today);

    setValidDate((prev) => ({ ...prev, [name]: false }));

    if (!day.isValid()) {
      setShowErrors((prev) => ({ ...prev, [name]: errors.invalidDate }));
      return;
    }

    if (!isSameOrAfter) {
      setShowErrors((prev) => ({ ...prev, [name]: errors.invalidRange }));
      return;
    }
    
    setValidDate((prev) => ({ ...prev, [name]: day.format("MM/DD/YY") }));
    setShowErrors((prev) => ({ ...prev, [name]: false }));
    return;
  };

  const handleFormatting = (target) => {
    if (typeof target.value !== "string") return;

    // Strip everything but the numbers and convert to an array
    const targetValue = target.value.replace(/\D/g, "").trim().split("");
    const submitForValidation = targetValue.length >= 5;

    let formatted = "";
    targetValue.forEach((letter, i) => {
      if (i > 5) return;

      formatted = formatted + letter;
      if (
        (i === 1 && targetValue.length >= 3) ||
        (i === 3 && targetValue.length >= 5)
      ) {
        formatted = formatted + "/";
      }
    });

    target.value = formatted;

    if (submitForValidation) {
      handleValidation(dayjs(formatted, "MM/DD/YY").toDate());
    }
  };

  const isError = typeof showErrors[name] === 'string' && !validDate[name];

  return (
    <ErrorContainer className="dateInput">
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        <DatePicker
          id={id}
          minDate={new Date()}
          selected={selectedDate}
          dateFormat="MM/dd/yy"
          placeholderText="mm/dd/yy"
          onChange={handleValidation}
          onChangeRaw={(event) => handleFormatting(event.target)}
          className={isError ? "error" : ""}
          aria-error-message={`${name}error`}
        />
      </div>
      {isError && (
        <div id={`${name}error`} className="error-container-message">
          {showErrors[name]}
        </div>
      )}
    </ErrorContainer>
  );
};

export default DateInput;
