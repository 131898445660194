import { postalCodeLookup } from "../../actions";

const populateZipFromGeo = ({
  geoLocation,
  setGeoLocation,
  setCleanedZipcode,
  setZipcode,
  setErrorGeolocation
}) => {
  if (geoLocation) {
    setZipcode(geoLocation);
  } else {
    postalCodeLookup({
      setGeoLocation,
      setCleanedZipcode,
      setZipcode,
      setErrorGeolocation
    });
  }
};

export default populateZipFromGeo;
