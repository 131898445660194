import styled from "@emotion/styled";

const ErrorContainer = styled.div`
  position: relative;
  & .error-container-message {
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    color: red;
    left: 0;
    white-space: nowrap;
  }
`;

export default ErrorContainer;
