
import React, { memo, Fragment } from "react";
import styled from "@emotion/styled";


const AppliedContainer = styled.ul`
list-style-type: none;
margin: 0;
padding: 0;
display: flex;
margin: 0 -5px;
flex-wrap: wrap;
flex-basis: 76%;
li {
  margin: 0;
  padding: 0;
}
span {
  flex: 0 0 auto;
  font-size: 14px;
  margin: 0 5px;
  border: none;
  background: transparent;
  margin-bottom: 16px;
  color: ${props => props.theme.color.uiPrimary};
}
`;

const areEqual = (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  };

  const AppliedFilters = memo( ({ filters, activeFilters }) => {
    const getFilterDisplayName = codeName => {
    if (filters.length) {
      const filterItems = filters.filter(item => item.filterCodeName === codeName);
      if (filterItems.length) {
        return filters.filter(item => item.filterCodeName === codeName)[0]
            .filterDisplayName;
        }
      } else {
        return null;
      }
    };

    const keys = Object.keys(activeFilters);
    const index = keys.indexOf("filter_specialtytype");
    if (index > -1) {
      keys.splice(index, 1);
    }

    const cb = (i, index) => (
      <li key={`i_${index}`}>
          <span>{(index ? '| ' : '') + getFilterDisplayName(i)}</span>
       </li>
    );
    
    let appliedCount = 0;
    const ui = keys.filter(key => activeFilters[key].size).map((i, index) => {
      const filterName = getFilterDisplayName(i)
      //console.log(filterName)
      if (filterName !== undefined) {
        appliedCount++;
        return (cb(i, index))
      } else {
        return null
      }
    });
    
    return appliedCount > 0 ? <Fragment><span>Applied Filters: </span><AppliedContainer>{ui}</AppliedContainer></Fragment> : null 
  }, areEqual);

  export default AppliedFilters;