import React from 'react';
import styled from '@emotion/styled';

const FormContainer = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
  background-color: ${props => props.theme.color.grey};
  padding: 16px 30px 32px 30px;
  ${props => props.theme.breakpoint.mobileonly} {
    padding: 16px 12px 16px 12px;
    font-size: 11px;
  }
  h3 {
    font-size: 16px;
    margin-top: 0;
  }
  .inner {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 1rem;
  }
  .text-input {
    flex-basis: 114px;
    display: flex;
    margin: 0 10px;
  }
  .link-container {
    display: flex;
    justify-content: flex-start;
    margin: 0 -2rem;
  }
`;

const BoxWithForm = props => {
  if (props.heading) {
    var headingTag = props.headingTag === undefined ? "" : "h2";
    if (headingTag == "h2")
    {
      return <FormContainer><h2 style={{ color: "#000", fontSize: "18px", fontFamily: "Gotham Bold" }}>{props.heading}</h2>{props.children}</FormContainer>
    }
    else {
      return <FormContainer><h3 style={{ fontSize: "18px" }}>{props.heading}</h3>{props.children}</FormContainer>
    }

  }
  else {
    return <FormContainer>{props.children}</FormContainer>;
  }
};

export default BoxWithForm;
