import React from "react";
import { ScreenReaderText } from "../../styledComponents";
import styled from "@emotion/styled";

const Container = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
  flex-basis: 76%;
  li {
    margin: 0;
    padding: 0;
  }
  span {
    flex: 0 0 auto;
    font-size: 14px;
    margin: 0 5px;
    border: none;
    background: transparent;
    margin-bottom: 16px;
    color: ${props => props.theme.color.uiPrimary};
    //font-weight: bold;
    //cursor: pointer;
    // svg {
    //   color: ${props => props.theme.color.uiPrimary};
    // }
    // &:focus,
    // &:hover {
    //   color: ${props => props.theme.color.uiPrimary};
    // }
  }
`;

const ClearFiltersNew = ({ clearHandler, activeFilters, filters }) => {
   const getDisplayName = codeName => {
    return filters.filter(item => codeName != "filter_specialtytype" && item.filterCodeName === codeName)[0]
      .filterDisplayName;
  };

  const cb = i => (
    <li key={i}>
      {/* <button
        value={i}
        onClick={e => clearHandler(e.currentTarget.value)}
        type="button"
      > */}
        {/*
        <i className="fa-light fa-circle-xmark"></i>
        <ScreenReaderText>Clear filters for</ScreenReaderText>{" "}  */}
        <span>{getDisplayName(i)}</span>
       {/* </button> */}
     </li>
  );
  const keys = Object.keys(activeFilters);

  const ui = keys.filter(key => activeFilters[key].size).map(cb);

  return <Container>Applied Filters: {ui}</Container>;
};

export default ClearFiltersNew;
