import React, { Fragment } from "react";
import styled from "@emotion/styled";
import DetailsSummaryNew from "../DetailsSummaryNew";
import DetailsSummary from "../DetailsSummary";
import FormCheckBoxNew from "./FormCheckBoxNew";

const Container = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  width: 434px;
  display: block;

  ${(props) => props.theme.breakpoint.mobileonly} {
    width: 100%;
  }
`;

const Button = styled.button`
  background: ${(props) => (props.primary ? props.theme.color.uiPrimary : "#fff")};
  color: ${(props) => (props.primary ? "#fff" : props.theme.color.uiPrimary)};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  width: 140px;
  padding: 0.25em 1em;
  border: 1px solid ${(props) => (props.primary ? "transparent" : props.theme.color.uiPrimary)};
  border-radius: 3px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  outline: none;

  &:focus {
    outline:2px dotted grey;
  }

  &.btn-reset-filter {
    &:hover,&:focus {
      background-color:${(props) => props.theme.color.uiPrimary}
      color:#ffffff;
    }
  }

  &.btn-apply-filter {
    &:hover,&:focus {
      background: ${(props) => props.theme.color.uiPrimaryActive};
      border-color: ${(props) => props.theme.color.uiPrimaryActive};
    }
  }
`;

const FilterGroupsNew = ({
  filters,
  checkhandler,
  activefilters,
  clearhandler,
  applyfilter,
  groupscollapsible,
  resethandler,
}) => {
  // const handleClearAllFilters = () => keys.forEach(key => clearhandler(key));
  // const keys = Object.keys(activefilters).filter(
  //   filt => activefilters[filt].size
  // );

  const thesefilters = filters.map(
    ({ filterCodeName, filterDisplayName, filterItems }) => {
      return (
        <Container key={filterCodeName}>
          {groupscollapsible ? (
            <DetailsSummary
              border={true}
              padding={true}
              background={true}
              summary={filterDisplayName}
              disabled="disabled"
              groupsCollapsible={groupscollapsible}
            >
              <FormCheckBoxNew
                key={filterCodeName}
                fieldCodeName={filterCodeName}
                onChangeHandler={checkhandler}
                options={filterItems}
                legend={filterDisplayName}
                value={activefilters[filterCodeName]}
              />
            </DetailsSummary>
          ) : (
            <DetailsSummaryNew
              border={true}
              padding={true}
              background={true}
              summary={filterDisplayName}
              disabled="disabled"
              groupsCollapsible={groupscollapsible}
            >
              <FormCheckBoxNew
                key={filterCodeName}
                fieldCodeName={filterCodeName}
                onChangeHandler={checkhandler}
                options={filterItems}
                legend={filterDisplayName}
                value={activefilters[filterCodeName]}
              />
            </DetailsSummaryNew>
          )}
        </Container>
      );
    },
  );

  return (
    <Fragment>
      {thesefilters}
      <Button primary onClick={applyfilter} className="btn-apply-filter">
        Apply Filter
      </Button>
      <Button onClick={resethandler} className="btn-reset-filter">
        Reset Filter
      </Button>
    </Fragment>
  );
};

export default FilterGroupsNew;
