import React, { useEffect, useState } from "react";
import deburr from "lodash/deburr";
import uniqueId from "lodash/uniqueId";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import styled from "@emotion/styled";
import axios from "axios";

const Form = styled.form`
  display: flex;
  position: relative;
  display: inline-block;
  height: 54px;
  max-width: 100%;
  .c-banner & {
    margin-bottom: 40px;
  }
  .text-field {
    fieldset {
      border-radius: 3px;
      background: #ffffff;
      margin-top: 4px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    input {
      position: relative;
      width: 668px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 18px 0 18px 18px;
      z-index: 1;
      color: #0e0e0e;
      font-weight: 500;
    }
  }
  .MuiMenuItem-root {
    white-space: normal;
    height: 48px;
  }
  .MuiDrawer-modal & {
    height: 32px;
    width: 240px;
    .text-field input {
      padding: 6px 16px 6px 16px;
      font-size: 16px;
      button {
        font-size: 1rem;
      }
    }
  }
  header & {
    height: 40px;
    width: 317px;
    margin-bottom: 0;

    .text-field input {
      padding: 11px 0 11px 18px;
    }
  }
  .MuiDrawer-modal &,
  header & {
    .text-field fieldset {
      border: 1px solid ${props => props.theme.color.greyDark};
      border-radius: 20px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .text-field fieldset {
        margin-top: -2px;
        margin-bottom: 8px;
      }
      button[type="submit"],
      input {
        top: -6px;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.color.uiTertiary}!important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #696969 !important;
  }

  label {
    color: #696969;
    z-index: 1;
  }
  .MuiInputLabel-formControl {
    transform: translate(15px, 20px) scale(1) !important;
  }
  .MuiInputLabel-shrink {
    transform: translate(17px, 5.5px) scale(0.75) !important;
  }

  button {
    color: ${props => props.theme.color.uiPrimary};
    cursor: pointer;
    font-size: 1.4rem;
    z-index: 1;
    .MuiDrawer-modal & {
      font-size: 1rem;
      color: ${props => props.theme.color.uiSecondary};
    }
    header & {
      font-size: 1rem;
      color: ${props => props.theme.color.uiSecondary};
    }
  }
  ul {
    li {
      padding-left: 0;
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    height: "250px !important",
    overflowY: "scroll"
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
  // input: {
  //   marginTop: "-16px"
  // }
  // divider: {
  //   height: theme.spacing(2)
  // }
}));

export default function LocationSearchBox({
  predictivesearchapinew,
  onSubmitHandler,
  placeholder,
  searchTerm
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: "",
    popper: ""
  });

  const [id] = useState(uniqueId("search-box-unique-"));

  useEffect(() => {
    setState(prev => {
      const copiedState = Object.assign({}, prev);
      copiedState.single = searchTerm;
      return copiedState;
    });
  }, [searchTerm]);

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       console.log("Enter key was pressed. Run your function.");
  //       onSubmitHandler(state.single);
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    // Remove a11y warning about aria not supported on the role of the element.
    delete other["aria-autocomplete"];

    return (
      <TextField
        fullWidth
        id={id}
        className="text-field floating-label"
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          label: placeholder,
          classes: {
            input: classes.input
          },

          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" aria-label="search" type="submit">
                <i className="fa-regular fa-magnifying-glass"></i>
              </IconButton>
            </InputAdornment>
          )
        }}
        {...other}
      />
    );
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <span>{suggestion.label}</span>
      </MenuItem>
    );
  };

  const getSuggestions = value => {};

  const getSuggestionValue = suggestion => {
    return suggestion.label;
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 2) {
      axios.get(predictivesearchapinew, { params: { q: value } }).then(res => {
        setSuggestions(res.data.map(item => ({ label: item })));
      });
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue
    });
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    //event.preventDefault();
    // console.log('what');
    //  return;
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion
  };

  return (
    <div className={classes.root} data-test="false">
      <Form
        onSubmit={e => {
          e.preventDefault();

          onSubmitHandler(state.single);
        }}
      >
        <Autosuggest
          {...autosuggestProps}
          id="locationsearch"
          inputProps={{
            classes,
            id,
            label: placeholder,
            value: state.single,
            onChange: handleChange("single"),
            InputLabelProps: {}
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => {
            // remove role=listbox from this element as it's not needed and causes a11y warnings.
            delete options.containerProps.role;

            return (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            );
          }}
        />
      </Form>
    </div>
  );
}
