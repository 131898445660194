import React, { useEffect, useState } from "react";
import deburr from "lodash/deburr";
import uniqueId from "lodash/uniqueId";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import styled from "@emotion/styled";
import axios from "axios";

import { Button } from "../../styledComponents";
import { MarginedBox } from "../../styledComponents";
import { ActionLinkWithIcon } from "../../components";
import { populateZipFromGeo } from "../../actions";
import { ButtonWithTooltip } from "../../components";

const Form = styled.form`
  display: flex;
  position: relative;
  max-width: 100%;
  justify-content: center;
  @media all and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
  &.is-stacked {
    flex-direction: column;
    height: auto;
    padding-top: 1rem;
    .text-field {
      margin-bottom: 1rem;
    }
    .text-field.zip {
      margin: 0;
      margin-bottom: 1rem;
    }
    .link-container {
      margin-bottom: 1rem;
    }
    .link-container > div {
      margin-top: 0 !important;
    }
    span.text-spacer {
      color: ${(props) => props.theme.color.black};
      height: auto;
      padding: 0 0 10px 0;
      @media all and (min-width: 1024px) {
        padding: 0 0 1rem 0;
      }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #3f51b5 !important;
    }
  }
  
  .c-banner & {
    max-width: 750px;
    margin: 0 auto 40px auto;
    @media all and (min-width: 1024px) {
      flex-wrap: wrap;
    }
    div[role="combobox"]:first-of-type {
      flex: 1 0 100%;
    }
    div[role="combobox"]:nth-of-type(2) {
      flex: 1 0 60%;
    }
    span.text-spacer {
      flex: 1 0 100%;
      justify-content: center;
      padding: 0;
      @media all and (max-width: 1024px) {
        margin: 10px 0;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.color.Tertiary}!important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #696969 !important;
  }

  label {
    z-index: 1;
    color: #696969;
  }
    
  .MuiInputLabel-formControl {
    transform: translate(15px, 20px) scale(1) !important;
  }
  .MuiInputLabel-shrink {
    transform: translate(17px, 5.5px) scale(0.75) !important;
  }

  .text-field {
    fieldset {
      border-radius: 3px;
      background: #ffffff;
      margin-top: 5px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    input {
      position: relative;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 18px 0 18px 18px;
      z-index: 1;
      box-shadow: rgb(0 0 0 / 9%) 0px 8px 10px -6px;
      @media all and (min-width: 1024px) {
        width: 100%;
      }
    }
  }
  .text-field.zip {
    margin: 15px 0;
    @media all and (min-width: 1024px) {
      margin: 0 0 0 10px;
    }
    input {
      @media all and (min-width: 1024px) {
        width: 100%;
      }
    }
  }

  .zip-location {
    display: flex;
    flex-direction: column;
    position: relative;
    &.is-landing {
      .text-field.zip {
        //margin-bottom: 1.75rem;
      }
      //.is-separator {
      //  color: #ffffff;
      //}
      .link-container {
        @media all and (max-width: 1024px) {
          margin-bottom: 1rem;
        }
      }
      button {
        color: #ffffff;
      }
      .text {
        background-image: repeating-linear-gradient(
          0deg,
          #ffffff,
          #ffffff 2px,
          transparent 0,
          transparent 2em
        );
        color: #ffffff;
      }
      .error {
        color: #ffffff;
      }
    }
    .error {
      color: rgb(0, 118, 168);
      margin: 10px 0;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
    }
  }

  button[type="submit"] {
    height: 55px;
    min-height: 55px;
    flex: 1 0 100%;
    margin-top: 15px;
    padding: 0;
  }

  #react-autowhatever-doctorSearch,
  #react-autowhatever-symptomSearch,
  .jss2,
  .jss12,
  .makeStyles-suggestionsContainerOpen-12,
  .makeStyles-suggestionsContainerOpen-2 {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    color: #000000 !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    & > div {
      margin-top: 15px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  // A bit of a hack to make sure we're only targeting browsers that support :has()
  #react-autowhatever-doctorSearch:not(:has(div)),
  #react-autowhatever-symptomSearch:not(:has(div)) {}
    overflow: hidden;
  }
  #react-autowhatever-doctorSearch:has(div),
  #react-autowhatever-symptomSearch:has(div) {
    overflow-y: auto;
  }

  div.jss11[aria-expanded="false"],
  div.makeStyles-container-11[aria-expanded="false"],
  div.jss36[aria-expanded="false"],
  div.makeStyles-container-36[aria-expanded="false"],
  div.jss22[aria-expanded="false"],
  div.makeStyles-container-22[aria-expanded="false"] {
    .seeAll {
      display: none;
    }
  }

  .MuiMenuItem-root {
    white-space: normal;
    //height: 48px;
    a {
      line-height: 1.5 !important;
    }
  }

  .MuiDrawer-modal & {
    // height: 31px;
    width: 240px;
    .text-field input {
      // padding: 6px 16px 6px 16px;
      font-size: 16px;
      button {
        font-size: 1rem;
      }
    }
  }
    // Prevent style bleeding
  // header & {
  //   height: 40px;
  //   width: 317px;
  //   margin-bottom: 0;

  //   .text-field input {
  //     padding: 11px 0 11px 18px;
  //   }
  // }
  .MuiDrawer-modal & {
  //header & {
    .text-field fieldset {
      border: 1px solid ${(props) => props.theme.color.greyDark};
      border-radius: 20px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .text-field fieldset {
        margin-top: -2px;
        margin-bottom: 8px;
      }
      button[type="submit"],
      input {
        top: -6px;
      }
    }
  }
  &.is-stacked {
    .MuiDrawer-modal & {
      width: 95%;
      .text-field {
        margin-bottom: 10px;
        &.zip {
          margin-bottom: 1rem;
        }
      }
      .text-field fieldset {
        border-color: rgba(0, 0, 0, 0.23);
        border-radius: 3px;
      }
      button[type="submit"] {
        height: 42px;
        color: #ffffff !important;
      }
    }
  }
  button {
    .MuiDrawer-modal & {
      font-size: 14px;
      color: ${(props) => props.theme.color.uiSecondary};
    }
    header & {
      font-size: 1rem;
      color: ${(props) => props.theme.color.uiSecondary};
    }
  }
  ul {
    li {
      padding-left: 0;
      margin-bottom: -6px;
    }
  }
  .title {
    text-align: left;
    padding: 14px 0 0 14px;
    font-weight: bold;
    color: ${(props) => props.theme.color.uiSecondary};
  }
  .seeAll {
    text-align: left;
    margin-left: 15px;
    padding: 15px 0;
  }
  .jss14,
  .jss39,
  .makeStyles-suggestionsList-14 {
    li:last-child {
      &:after {
        content: "";
        height: 1px;
        width: 97%;
        background: ${(props) => props.theme.color.greyDark};
        display: block;
        margin: 0 15px;
      }
    }
    a.aMenu {
      font-size: 16px;
      font-family: "Gotham Book";
      font-weight: 600;
      color: #000000;

      &:hover {
        background: none;
      }
    }
  }
  ul[role="listbox"] a.aMenu {
    font-size: 16px;
    font-family: "Gotham Book";
    font-weight: 600;
    color: #000000;

    &:hover {
      background: none;
    }
  }
  .text-spacer {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: #ffffff;
    height: 55px;
    font-family: ${(props) => props.theme.fonts.fontSecondary};
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // [theme.breakpoints.up('md')]: {
    //   display: "flex",
    //   justifyContent: 'center',
    // }
  },
  container: {
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  // input: {
  //   marginTop: "-16px"
  // }
  // divider: {
  //   height: theme.spacing(2)
  // }
}));

export default function DoctorSearchBox({
  onsubmithandler,
  placeholder,
  zipplaceholder,
  doctorsearchboxtextplaceholder,
  searchbuttonhelptext,
  searchTerm,
  predictivesearchapi,
  //handlezipinput,
  zipcode,
  showGuide,
  specialtytype,
  doctorsearch,
  setDoctorSearch,
  errorGeoLocation,
  geoEnabled,
  geoLocation,
  setGeoLocation,
  setErrorGeolocation,
  setCleanedZipcode,
  setZipcode,
}) {
  const classes = useStyles();
  const [tempSearchTerm, setTempSearchTerm] = useState(searchTerm);
  const [tempZipCode, setTempZipCode] = useState(zipcode);
  const [didMount, setDidMount] = useState(false);
  const [disabled, setDisabled] = useState(searchTerm != "" ? false : true);
  const [state, setState] = React.useState({
    single: "",
    popper: "",
    docsingle: "",
  });
  const [savedFilterParams, setSavedFilterParms] = useState();

  const [id] = useState(uniqueId("search-box-unique-"));

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    setState((prev) => {
      const copiedState = Object.assign({}, prev);
      if (doctorsearch) {
        copiedState.single = "";
        copiedState.docsingle = searchTerm;
      } else {
        copiedState.docsingle = "";
        copiedState.single = searchTerm;
      }

      return copiedState;
    });
  }, [searchTerm]);

  useEffect(() => {
    if (didMount) {
      setTempZipCode(zipcode);
    }
  }, [zipcode]);

  useEffect(() => {}, [disabled]);

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const renderDoctorInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    // Remove a11y warning about aria not supported on the role of the element.
    delete other["aria-autocomplete"];

    return (
      <TextField
        fullWidth
        id={id}
        className="text-field"
        variant="outlined"
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
          onPaste: function (e) {
            const newValue = (
              e.clipboardData || window.e.clipboardData
            ).getData("text");
            setDoctorSearch(true);
            handleDoctorChange("docSingle", e, newValue);
          },
        }}
        {...other}
      />
    );
  };

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    // Remove a11y warning about aria not supported on the role of the element.
    delete other["aria-autocomplete"];

    return (
      <TextField
        fullWidth
        id={id}
        className="text-field"
        variant="outlined"
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  };

  function renderSectionTitle(section) {
    return <div className="title">{section.title}</div>;
  }

  function getSectionSuggestions(section) {
    return section.section;
  }

  const isDisabled = () => {
    if (disabled) {
      return true;
    } else if (!disabled) {
      return false;
    }
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.url ? (
          <a className="aMenu" href={suggestion.url} rel="noreferrer">
            <span>{suggestion.text}</span>
          </a>
        ) : (
          <span>{suggestion.text}</span>
        )}
      </MenuItem>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <div>
          Press Enter to choose search term<strong>{query}</strong>
        </div>
      </div>
    );
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion.text;
  };

  const filterData = (rawData) => {
    let result = [];
    let sectionWrap = {};
    let sectionArr = [];

    if (rawData.conditions && rawData.conditions.bucketResults) {
      let innerLen = rawData.conditions.bucketResults.length;
      for (let i = 0; i < innerLen; i++) {
        if (
          rawData.conditions.bucketResults &&
          rawData.conditions.bucketResults[i].text &&
          rawData.conditions.bucketResults[i].params
        ) {
          sectionArr.push({
            text: rawData.conditions.bucketResults[i].text,
            params: rawData.conditions.bucketResults[i].params,
          });
        }
      }
      sectionWrap.title = rawData.conditions.bucketLabel;
      sectionWrap.section = sectionArr;
      result.push(sectionWrap);
      sectionArr = [];
      sectionWrap = {};
    }

    if (rawData.doctors && rawData.doctors.bucketResults) {
      let innerLen = rawData.doctors.bucketResults.length;
      for (let i = 0; i < innerLen; i++) {
        if (
          rawData.doctors.bucketResults &&
          rawData.doctors.bucketResults[i].text &&
          rawData.doctors.bucketResults[i].url
        ) {
          sectionArr.push({
            text: rawData.doctors.bucketResults[i].text,
            url: rawData.doctors.bucketResults[i].url,
          });
        }
      }
      sectionWrap.title = rawData.doctors.bucketLabel;
      sectionWrap.section = sectionArr;
      result.push(sectionWrap);
      sectionArr = [];
      sectionWrap = {};
    }

    if (rawData.noResults) {
      sectionArr.push({ text: "No results found" });
      sectionWrap.title = rawData.noResults.bucketLabel;
      sectionWrap.section = sectionArr;
      result.push(sectionWrap);
      sectionArr = [];
      sectionWrap = {};
    }
    return result;
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 2) {
      axios
        .get(predictivesearchapi, {
          params: {
            q: value,
            specialtytype: specialtytype,
            doctorSearch: doctorsearch,
          },
        })
        .then((res) => {
          setSuggestions(filterData(res.data));
        });
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    if (!suggestion.url && suggestion.params) {
      if (disabled) {
        setDisabled(false);
      }
      setSavedFilterParms(suggestion.params);
      setTempSearchTerm(suggestion.text);
      setState({
        ...state,
        single: suggestion.text,
        docsingle: "",
      });
    } else if (suggestion.url) {
      setTempSearchTerm("");
      setState({
        ...state,
        single: "",
        docsingle: "",
      });
      if (event.key == "Enter") {
        location.href = suggestion.url;
      }
    } else if (suggestion.noResults) {
      setTempSearchTerm("");
      setState({
        ...state,
        single: "",
        docsingle: "",
      });
    }
  };

  const handleChange =
    (name) =>
    (event, { newValue }) => {
      setState({
        ...state,
        [name]: newValue,
        docSingle: "",
      });
      setDoctorSearch(false);

      if (!disabled) {
        setDisabled(true);
      }
    };

  const handleDoctorChange =
    (name) =>
    (event, { newValue }) => {
      setState({
        ...state,
        [name]: newValue,
        single: "",
      });
      setDisabled(true);

      if (newValue != "") {
        setDoctorSearch(true);
        if (newValue.length >= 2) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        setDoctorSearch(false);
      }
    };

  const handleZipInput = (e) => {
    const val = parseInt(e.target.value.trim());
    setZipcode((prev) => {
      let zip = "";
      if (!val) {
        return zip;
      }
      zip = Number.isInteger(val) ? [val].join() : prev;
      if (zip.length > 5) {
        zip = zip.substring(0, 5);
      }
      return zip;
    });

    if (
      (!isNaN(val) && specialtytype && specialtytype != "") ||
      (searchTerm && searchTerm != "")
    ) {
      if (disabled) {
        setDisabled(false);
      }
    } else {
      if (!disabled) {
        setDisabled(true);
      }
    }
  };

  const handleDoctorSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 2) {
      axios
        .get(predictivesearchapi, {
          params: {
            q: value,
            specialtytype: specialtytype,
            doctorSearch: doctorsearch,
          },
        })
        .then((res) => {
          setSuggestions(filterData(res.data));
        });
    }
  };
  const autosuggestProps = {
    multiSection: true,
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
    renderSuggestionsContainer,
    renderSectionTitle,
    getSectionSuggestions,
  };

  const doctorAutosuggestProps = {
    multiSection: true,
    renderInputComponent: renderDoctorInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleDoctorSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
    renderSuggestionsContainer,
    renderSectionTitle,
    getSectionSuggestions,
  };

  const geoLocationButtonUI = () => {
    if (!geoEnabled) {
      return null;
    }
    return errorGeoLocation ? (
      <div role="alert">
        <span className="error">{errorGeoLocation}</span>
      </div>
    ) : (
      <div>
        <div className="link-container">
          <MarginedBox>
            <ActionLinkWithIcon
              text="Use my current location"
              action={() => {
                populateZipFromGeo({
                  geoLocation,
                  setGeoLocation,
                  setCleanedZipcode,
                  setZipcode,
                  setErrorGeolocation,
                });
              }}
            >
              <i className="fa-solid fa-location-dot"></i>
            </ActionLinkWithIcon>
          </MarginedBox>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root} data-test="false">
      <Form
        className={`${!showGuide ? "is-stacked" : ""}`}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
          if (e.target.type === "submit") {
            if (!disabled) {
              if (doctorsearch) {
                onsubmithandler(state.docsingle);
                setZipcode("");
              } else {
                onsubmithandler(state.single);
              }
            } else {
              onsubmithandler("");
            }
          }
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (!disabled) {
            if (doctorsearch) {
              onsubmithandler(state.docsingle);
              setZipcode("");
            } else {
              onsubmithandler(state.single);
            }
          } else {
            onsubmithandler("");
          }
        }}
      >
        <Autosuggest
          {...doctorAutosuggestProps}
          id="doctorSearch"
          inputProps={{
            classes,
            id: "doctor-text-box",
            label: doctorsearchboxtextplaceholder,
            value: state.docsingle,
            onChange: handleDoctorChange("docsingle"),
            InputLabelProps: {
              id: "label-doctor-text-box",
            },
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={({ containerProps, children, query }) => {
            // remove role=listbox from this element as it's not needed and causes a11y warnings.
            delete containerProps.role;
            return <div {...containerProps}>{children}</div>;
          }}
        />
        <span className="text-spacer"> OR </span>

        <Autosuggest
          {...autosuggestProps}
          id="symptomSearch"
          inputProps={{
            classes,
            id: "symptom-text-box",
            label: placeholder,
            value: state.single,
            onChange: handleChange("single"),
            InputLabelProps: {
              id: "label-symptom-text-box",
            },
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={({ containerProps, children, query }) => {
            // remove role=listbox from this element as it's not needed and causes a11y warnings.
            delete containerProps.role;
            return <div {...containerProps}>{children}</div>;
          }}
        />

        <div className={`zip-location ${showGuide ? "is-landing" : ""}`}>
          <FormControl className="text-field zip">
            <InputLabel htmlFor="doctor-zip-code">{zipplaceholder}</InputLabel>
            <OutlinedInput
              id="doctor-zip-code"
              value={zipcode}
              onChange={handleZipInput}
              label="Name"
            />
          </FormControl>
          {geoLocationButtonUI()}
        </div>

        <ButtonWithTooltip
          searchbuttonhelptext={searchbuttonhelptext}
          isdisabled={isDisabled()}
        ></ButtonWithTooltip>
      </Form>
    </div>
  );
}
