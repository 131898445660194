const searchConditional = type =>
  ({
    location: {
      showingResultsMessage: "Locations",
      railH2: "Find a location near you",
      filter: "Filter Your Search",
      zipCodeUI: "See locations in your area:"
    },
    doctor: {
      showingResultsMessage: "Doctors",
      filter: "Filter Your Search",
      zipCodeUI: "See doctors in your area:"
    },
    event: {
      showingResultsMessage: "Events",
      railH2: "Find an event near you",
      filter: "Filter Your Search",
      zipCodeUI: "See events in your area:"
    },
    global: {
      showingResultsMessage: "Results",
      railH2: "Filter your search results",
      filter: "Filter Your Search",
      zipCodeUI: ""
    },
    press_release: {
      showingResultsMessage: "Press Releases"
    }
  }[type]);

export default searchConditional;
