import React, { lazy, Suspense } from "react";
import { LoadingIcon } from "../../components";

const SearchContainerNew = lazy(() => import("./SearchContainerNew"));

const SearchContainerWithSuspenseNew = props => {
  return (
    <Suspense fallback={<LoadingIcon />}>
      <SearchContainerNew {...props} />
    </Suspense>
  );
};

export default SearchContainerWithSuspenseNew;
