import styled from "@emotion/styled";

const Select = styled.select`
  border: 1px solid ${props => props.theme.color.greyDark};

  height: 100%;
  padding: 0 10px;
  width: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.1s ease-out;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  box-shadow: ${props => props.theme.boxShadow};
  &::-ms-expand {
    display: none;
  }
`;

export default Select;
