import React, { memo } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const CardHasImage = props => {
  const imgFadeIn = keyframes`
  from {
   opacity:0;
  }
  to {
   opacity:1;
  }
`;

  const Card = styled.div`
    display: block;

    border: 1px solid ${props => props.theme.color.greyDark};
    box-shadow: ${props => props.theme.boxShadow};
    ${props => props.theme.breakpoint.mobileonly} {
      /* border-left: none;
      border-right: none; */
    }
    margin-bottom: 20px;
    padding: 8px;
    ${props => props.theme.breakpoint.tablet} {
      padding: 0;
    }
    .margin-bottom {
      margin-bottom: 10px;
    }

    &.flex {
      display: flex;
      flex-wrap: wrap;
      ${props => props.theme.breakpoint.tablet} {
        flex-wrap: nowrap;
      }
    }
    .media {
      /* height:207.66px;
      height:202.66px; */
      width: 21%;
      flex: 0 0 21%;
       padding: 0 8px 8px 0;
      /* padding-right: 8px;
      padding-bottom:8px;
      padding-left:0;
      padding-top:100%; */
      position:relative;
      ${props => props.theme.breakpoint.tablet} {
        padding: 8px 0 8px 8px;
        /* padding-top:calc(100% + 8px);
        padding-right:0;
        padding-bottom:8px;
        padding-left:8px; */
      }

      .responsive-card-image{
        width:100%;
        /* padding-top:100%; */
        position:relative;
        height:100%;
        background:${props => props.theme.color.grey};
      }
      .standard-image{
        /* background:${props => props.theme.color.grey};
        width:100%;
        height:auto; */
      }
      img {
        /* max-width: 100%;
        position:absolute;
        top:0;
        left:0; */
        width:100%;
        height:100%;
        object-fit:cover;
      }
      ${props => props.theme.breakpoint.tablet} {
        /* width: 200px; */
        flex: 0 1 27%;
      }
      /* img {
        opacity:0;
        animation:fadeIn 300ms ease forwards;
      } */
      img{
        animation: ${imgFadeIn} 1000ms forwards;
      }
    }
    .text {
      padding: 25px 25px 10px 25px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 35%;

      display: ${props.variant === "global" ? "flex" : "block"};
      .flex-right {
        display: ${props.variant === "global" ? "flex" : "block"};
        flex-grow: ${props.variant === "global" ? "1" : "0"};
        flex-direction: ${props.variant === "global" ? "column" : "row"};
        align-items: ${props.variant === "global" ? "flex-end" : "flex-start"};
      }
      flex-direction: column;
      h3,
      h4 {
        margin-top: 0;
        margin-bottom: 13px;
      }

      /* h4{
      margin-bottom:10px;
    } */
      h3 + h4 {
        margin-top: -13px;
      }
      h3{
        font-size: 1.25rem;
        line-height: 1.2;
      }
      h4 {
        line-height: 1.2;
        font-size: 1.125rem;
      }

      /* ${props => props.theme.breakpoint.mobileonly} { */
        padding: 0;
        margin-bottom: 1rem;
        ${props => props.theme.breakpoint.mobileonly} {
        h3 {
          margin-top: 0.5em;
        }
       }
      ${props => props.theme.breakpoint.tablet} {
        /* padding: 20px 9px 20px 25px; */
        padding: ${
          props.variant === "global"
            ? "20px 25px 20px 25px"
            : "20px 9px 20px 25px"
        };
      }
    .multiday {
      display: flex;
      flex-direction: column;
      align:  center;
      justify-content: flext-start;
    }
    .multiday p {
      margin: .5em 0 1em 0;
      color: ${props => props.theme.color.uiSecondary};
      font-size: 14px;
      font-family: 'Gotham Bold';
      letter-spacing: -.12px;
      line-height: 1.15;
      text-transform: uppercase;
    }
    .multiday h3 {
        margin-bottom: 0;
      }
    }
    .summary-link-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        font-size: 13px;
      }
      span.inpatient-specialty {
      font-size: 70%;
      font-weight: bolder;
      margin-top: 10px;
      display: block;
      text-transform: uppercase;
    }

    span.black-thin {
      font-size: rem-calc(16px);
      text-decoration: none;
      font-size: 14px;
      letter-spacing: -0.12px;
      line-height: 1.15;
      text-decoration: none;
      position: relative;
    }
    }
    .fee {
      margin-right: 1rem;
      font-size: 14px;
      color: ${props => props.theme.color.alert};
    }
    .buttons {

      /* ${props => props.theme.breakpoint.mobileonly} {
        padding:0;
      } */
      padding:0;
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      ${props => props.theme.breakpoint.tablet} {
        padding: 20px 0;
        /* padding: 20px 0; */
        text-align: center;
        flex-direction: column;
        width: 235px;
      }
      .link3 {
        width: 100%;
        flex-shrink: 0;
        margin: auto 0 0 0;
        font-family: ${props => props.theme.fonts.fontBold};
        font-size:14px;
        /* color: ${props => props.theme.color.textColorSecondary};
        font-family: ${props => props.theme.fonts.fontBold}; */
        /* font-size:14px; */
      }

      div {
        margin-right: 15px;

        ${props => props.theme.breakpoint.tablet} {
          margin-right: 0;
          padding: 0 25px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .c-button {
        ${props => props.theme.breakpoint.mobileonly} {

          svg {
            display: none;
          }
        }
        padding: 13px 0;
        padding: 12px 17px;
        text-align: center;
        text-decoration: none;
        margin-bottom: 7px !important;
        /* ${props => props.theme.breakpoint.tablet} { */
          margin-bottom: 7px;
        /* } */
      }
    }
    .is-small {
        font-size: 14px;
        line-height: line-height: 14px;
    }
    .fax {
      margin: 15px 0 20px 0;
      font-family: 'Gotham Bold';
      font-size: 14px;
    }
  `;

  let test;
  if (props.SecondaryButtonTarget == "_blank") {
    test = "noreferrer"
  }

  return (
    <Card className={props.showImage ? "flex" : null}>
      {props.showImage && props.Image ? (
        <div className="media">
          {" "}
          <div
            className={
              props.variant === "doctor"
                ? "standard-image"
                : "responsive-card-image"
            }
          >
            <img src={props.Image} alt="" />
          </div>
        </div>
      ) : null}
      <div className="text">
        {props.IsMulti && props.MultiDateString ? (
          <div className="multiday">
            <span className="multiday-flag">{props.MultiFlagString}</span>
            {props.MultiDateString && <p>{props.MultiDateString}</p>}
            {props.Title && <h3>{props.Title}</h3>}
          </div>
        ) : props.IsMulti && !props.MultiDateString && props.Title ? (
            props.Title && <h3>{props.Title}</h3>
          )
          : props.Title && <h3>{props.Title}</h3> }

        {/*!props.IsMulti && props.Title ? (
          <h3>{props.Title}</h3>
        ) : null*/ }

        {props.IsMulti && !props.MultiDateString && props.MultiFlagString ? (
          <div class="multiday">
            <span class="multiday-flag">{props.MultiFlagString}</span>
          </div>
        ) : null}

        {props.Subtitle && <h4>{props.Subtitle}</h4>}
        {props.AdditionalInformation && <div class="margin-bottom c-media-card__alert" dangerouslySetInnerHTML={{__html: props.AdditionalInformation}}></div>}
        {props.Link1Text && !props.Link1Url.includes("google.com/maps/") && (
          <div className="margin-bottom">
            <a
              className="link-action link-action__blue"
              target={props.Link1Target}
              href={props.Link1Url}
            >
              <span>{props.Link1Text}</span>
            </a>
          </div>
        )}

        {props.Summary && props.Summary.length ? ( // virtual
          <div className="margin-bottom">
            {Array.isArray(props.Summary) &&
              props.Summary.map((text, i) => <div key={i}>{text}</div>)}
            {props.Fax && <div className="fax">Fax: {props.Fax}</div>}
          </div>
        ) : null}
        {props.Link1Text && props.Link1Url.includes("google.com/maps/") && ( // get directions
          <div className="margin-bottom">
            <a
              className="link-action link-action__blue text-transform__none"
              rel="noreferrer"
              target={props.Link1Target}
              href={props.Link1Url}
            >
              <i className="fa-solid fa-location-dot"></i>
              <span>{props.Link1Text}</span>
            </a>
          </div>
        )}
        {props.Summary2 && props.Summary2.length ? ( // summaery2
          <div className="margin-bottom test2">
            {Array.isArray(props.Summary2) &&
              props.Summary2.map((text, i) => <div key={i}><p className="is-small has-no-margin__top has-no-margin__bottom">{text}</p></div>)}
          </div>
        ) : null}
        <div>
          <ul className="summary-link-list">
            {Array.isArray(props.SummaryLinkList) &&
              props.SummaryLinkList.map(link => (
                <li key={link.LinkText + link.LinkUrl}>
                  <a className="font__reg link-black-thin" href={link.LinkUrl}>
                    {link.LinkText}
                  </a>
                </li>
              ))}
              {Array.isArray(props.DoctorSummaryLinkList) &&
              props.DoctorSummaryLinkList.map(link => (
                <li key={link.LinkText + link.LinkUrl}>
                  <a className="font__reg link-black-thin" href={link.LinkUrl}>
                    {link.LinkText}
                  </a>
                  {link.InPatientLabel && link.InPatientContent && (
                    <div className="inpatient-specialty-container">
                    <span class="inpatient-specialty">{link.InPatientLabel}</span>
                    <span class="black-thin">{link.InPatientContent}</span>
                    </div>
                    )}
                </li>
              ))}
          </ul>
        </div>
        {props.VirtualUrl && ( // ?
        <div className="margin-bottom directions">
        <a
          className="link-action link-action__blue"
          target="_blank"
          href={props.VirtualUrl}
        >
          <span>{props.VirtualUrlText}</span>
        </a>
        </div>
        )}

        <div>
          {props.Fee && (
            <strong className="fee">
              <i className="fa-light fa-circle-dollar"></i>
              <span>{props.Fee}</span>
            </strong>
          )}
          {props.Link2Text && (
            <a
              className=""
              target={props.Link2Target}
              href={props.Link2Url || "_self"}
            >
              {" "}
              <span>{props.Link2Text}</span>
            </a>
          )}
        </div>
        {props.variant === "global" &&
        props.SecondaryButtonText &&
        props.SecondaryButtonUrl ? (
          <div className="flex-right">
            <a
              className="link-action"
              rel={test}
              target={props.SecondaryButtonTarget}
              href={props.SecondaryButtonUrl}
            >
              <span>{props.SecondaryButtonText}</span>
              <i className="fa-light fa-chevron-right"></i>
            </a>
          </div>
        ) : null}
      </div>

      {props.variant === "global" ? null : (
        <div className="buttons">
          {props.PrimaryButtonText && (
            <div>
              <a
                className="c-button c-button__full"
                href={props.PrimaryButtonUrl}
              >
                {props.PrimaryButtonUrl &&
                props.PrimaryButtonUrl.startsWith("tel:") ? (
                  <>
                    <span className="a11y-hidden">Phone number </span>
                    <i className="fa-solid fa-phone"></i>
                  </>
                ) : null}
                <span>{props.PrimaryButtonText}</span>
              </a>
            </div>
          )}
          {props.SecondaryButtonText && (
            <div>
              <a
                className="c-button c-button__full c-button__secondary"
                target={props.SecondaryButtonTarget}
                href={props.SecondaryButtonUrl}
              >
                {props.SecondaryButtonText}
              </a>
            </div>
          )}

          {props.Link3Text && props.variant === "event" && (
            <a
              href={props.Link3Url}
              className="link3  link-action link-action__no-transform has-margin__bottom__small"
            >
              
              <i className="fa-regular fa-calendar-days"></i>
              <span>{props.Link3Text}</span>
            </a>
          )}
          {props.Link3Text && props.variant === "doctor" && (
            <span className="link3  has-margin__bottom__small">
              {" "}
              <i className="fa-regular fa-calendar-check"></i>
              <span>{props.Link3Text}</span>
            </span>
          )}
        </div>
      )}
    </Card>
  );

  // Address: "135b7311-d7e3-45da-966a-cef4a0174e84";
  // Fee: null;
  // Image: "https://maps.googleapis.com/maps/api/staticmap?center=26.5072630%2c-81.9131600&markers=color%3ared%7csize%3amid%7c26.5072630%2c-81.9131600&zoom=15&size=200x200&key=AIzaSyAWZQu-QpnjtZxwGOccr6B4yDTCdmc03g4";
  // Link1Text: null;
  // Link1Url: "https://www.google.com/maps/dir/9981+S.+HealthPark+Drive++Fort+Myers+FL+33908";
  // Link2Text: null;
  // Link2Url: null;
  // Link3Text: null;
  // Link3Url: null;
  // PrimaryButtonText: "239-343-2000";
  // PrimaryButtonUrl: "tell:2393432000";
  // Rating: null;
  // SecondaryButtonText: "View Details";
  // SecondaryButtonUrl: "/find-a-location/golisano-children’s-hospital-of-southwest-florida";
  // Subtitle: null;
  // Summary: "<span>9981 S. HealthPark Drive</span><span> Fort Myers, FL 33908</span>";
  // Title: "Golisano Children’s Hospital of Southwest Florida";
};

export default memo(CardHasImage);
