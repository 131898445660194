import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  margin-bottom: 1rem;
  margin-top: 25px;
  padding: 1rem 0;
  background-color: #ffffff;

  .wrapper {
    margin-bottom: 20px;
    background-color: ${props => props.theme.color.grey};
    min-height: 198px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    border-radius: 4px;
    border-bottom: 7px solid ${props => props.theme.color.uiPrimary};

    .title {
      font-size: 20px;
      font-family: Gotham Bold;
      display: block;
    }

    .cta {
      cursor: pointer;
      background-color: ${props => props.theme.color.uiPrimary};
      width: 220px;
      /*height: 47px;*/
      text-transform: uppercase;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 3px;
      padding: 15px 25px;
      margin-top: 20px;
      text-align: center;
      
      &:hover, &:focus {
        background-color: ${props => props.theme.color.uiPrimaryActive} !important;
        border-color: ${props => props.theme.color.uiPrimary} !important;
        color: #fff;
        background-size: auto !important;
        background-image: none !important;
      }

      &:focus {
        outline:2px dotted grey
      }
    }
  }
`;

const PrimaryButton = styled.a`
width: 296px;
display: flex;
justify-content: center;
flex-direction: row;
align-items: center;
margin: 0 auto 10px auto;
cursor: "pointer";
${props => props.theme.breakpoint.laptop} {
  max-width: 100%;
  margin: 0;
}
&.icon {
  border: none;
  outline: none;
}
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display:flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  ${props => props.theme.breakpoint.laptop} {
    flex-direction: row;
  }
  .text-spacer {
    padding: 0 1rem;
  }
`;



const SideCTAs = ({ctas, showMobile}) => {
  return (
    !showMobile ? (
    <Container>
      {ctas.map((item, i) => (
          <div key={i} className="wrapper">
            <span className="title">{item.title}</span>
            <a className="cta" href={item.button.url} target={item.button.target}>{item.button.text}</a>
          </div>
        )
      )}
    </Container> ) :
    (
      <ButtonContainer>
        <div>
      {ctas.map((item, i) => (
        <PrimaryButton
          key={i}
          className={`c-button c-button__primary`}
          href={item.button.url}
          target={item.button.target}>
            {item.button.text}
        </PrimaryButton>
      )
      )}
      </div>
      </ButtonContainer>
    )
  );
};
export default SideCTAs;