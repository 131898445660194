import styled from "@emotion/styled";

const SelectWrapper = styled.div`
  width: 64px;
    flex-grow: 0;
    margin: 0 10px;
    height:100%;
    position:relative;
    display: flex;
    align-items: center;
    background:#fff;
    .icon{
      position:absolute;
      right:9px;
      pointer-events:none;
      color:${props => props.theme.color.uiPrimary};
      top: 50%;
    transform: translateY(-50%));
    transform: translate(0, -50%);
    }
`;

export default SelectWrapper;
