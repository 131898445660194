import React, { useState, useEffect, Fragment } from "react";
import { FlexRow, EventCard, EventCardForPrint, ShowingResultsMessage } from "../../components";
import axios, { CancelToken } from "axios";
import { ThemeProvider } from "@emotion/react";
//import theme, { fontSize } from "../../../../theme";
import theme, { fontSize } from "../../../theme";
import searchConditional from "../../helpers/searchConditional";

const EventSearchPrint = ({
  searchapi,
  searchtype,
  childrenelement,
  noresults
}) => {

  const [searchTerm, setSearchTerm] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [searchRadius, setSearchRadius] = useState("");
  const [validDate, setValidDate] = useState({ start: "", end: "" });
  const [activeFilters, setActiveFilters] = useState({});
  const [stateMapped, setStateMapped] = useState(false);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState();
  const [receivedSearchParams, setSearchParams] = useState(new URLSearchParams(location.search.toLowerCase()));
  // const [loading, setLoading] = useState(true);

useEffect(() => {
  mapStateToUrl();
}, [])

useEffect(() => {
  if (stateMapped === true) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const fetchResults = async () => {
      // setResults(() => { return {}; });
      // setLoading(true);
      let queryParams = [];
      const keyNames = Object.keys(activeFilters);
      keyNames.forEach(key => {
          const filterTerms = [...activeFilters[key]].join(",");
          filterTerms.length && queryParams.push(`${key}=${filterTerms}`);
      });
      let additionalParamsString = "";
      if (queryParams.length) {
          additionalParamsString = `${queryParams.join("&")}`;
      }

      const res = axios.get(searchapi, { 
        cancelToken: source.token, 
        params: {
        q: searchTerm, 
        zip: zipCode, 
        radius: searchRadius,
        start: validDate.start,
        end: validDate.end, 
        page: 1, 
        additionalParamsString: additionalParamsString,
        isListingPage: false
        }
      })
      .then(res => {
        setResults(() => {
          return res.data;
        });
        setTotalResults(() => {
          return res.headers["x-pagination-total"];
        })
        parent.postMessage({action: 'events-print-loaded'})
        // setLoading(false);
      });

    }
    
    fetchResults();
  }
}, [stateMapped])

const mapStateToUrl = () => {
  if (receivedSearchParams != "")
  {
      let start, end;

      for (let [p0, p1] of receivedSearchParams) {
      
          if (p0 === "zip") {
              setZipCode(p1);
          }
          else if (p0 === "q")
          {
              setSearchTerm(p1);
          }
          else if (p0 === "radius")
          {
              setSearchRadius(p1);
          }
          else if (p0 === "start")
          {
              start = p1;
              setValidDate(prevState => ({
                  ...prevState,
                  start: `${p1.substring(4, 6)}/${p1.substring(6, 8)}/${p1.substring(
                      2,
                      4
                  )}`
              }));
          }
          else if (p0 === "end")
          {
              end = p1;
              setValidDate(prevState => ({
              ...prevState,
              end: `${p1.substring(4, 6)}/${p1.substring(6, 8)}/${p1.substring(
                  2,
                  4
              )}`
              }));
             
          }
      }
  }
  setStateMapped(true);
}

const getCards = () => {
  return (
    <Fragment>
    {results.groupingfields.map((gf, i) => {
        return (<Fragment key={i}><div style={{ marginTop: i ? "29px" : "0" }} key={i}>
                <FlexRow flex_end>
                <h2>{gf.text}</h2>
                </FlexRow>
        </div>
        {gf.events.map((evt, j) => {
            return (<EventCard
                key={j}
                {...evt}
            />)
        })}
        </Fragment>
    )}
  )}
  </Fragment> 
  )
}

return (
  <ThemeProvider theme={theme}>
    <section className="grid-4">
      <div className="grid-4-center site-container">
        <div className="grid-right-rail__wrapper ">
          <div className="grid-right-rail">
            <div className="has-padding__top grid-right-rail-main__center">
              <FlexRow hasBorder>
                <ShowingResultsMessage
                    loading={false}
                    msg={searchConditional(searchtype).showingResultsMessage}
                    data={results}
                    searchTerm={searchTerm}
                    searchType={searchtype}
                    noresults={noresults}
                    totalResults={totalResults}
                  />
              </FlexRow>
              {results && results.groupingfields && results.groupingfields.length ? getCards() : null }
            </div>
          </div>
        </div>
      </div>
    </section>
  </ThemeProvider>
)

}

export default EventSearchPrint;