import React, { lazy, Suspense } from "react";
import { LoadingIcon } from "../../components";

const LocationSearchContainer = lazy(() => import("./LocationSearchContainer"));

const LocationSearchContainerWithSuspense = props => {
  return (
    <Suspense fallback={<LoadingIcon />}>
      <LocationSearchContainer {...props} />
    </Suspense>
  );
};

export default LocationSearchContainerWithSuspense;
