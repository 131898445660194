import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
//import theme from "../../../../theme";
import theme from "../../../theme";
import {
  SearchBox,
  RemoteSearchBox,
  AlphabetPicker,
  LoadingIcon
} from "../../components";
import axios from "axios";
import styled from "@emotion/styled";

const FindAService = ({
  searchapi,
  predictivesearchapi,
  searchboxtextplaceholder,
  childrenelement,
  redirect
}) => {
  const [selectedLetter, setSelectedLetter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchDataWithQuery, setSearchDataWithQuery] = useState([]);
  const [enabledLetters, setEnabledLetters] = useState([
    ..."abcdefghijklmnopqrstuvwxyz"
  ]);
  const receivedSearchParms = new URLSearchParams(location.search);
  const uiElement = useRef(null);
  const Container = styled.section`
    .loading {
      text-align: center;
      padding: 2rem 0 1rem 0;
    }
    .find_a_service_UI_container {
      position: relative;
    }
    .find_a_service_results_container {
      margin-right: 50px;
      min-height: 700px;
      ${props => props.theme.breakpoint.laptop} {
        min-height: 0;
        margin-right: 0;
      }
    }
    .category_letter {
      font-size: 20px;
      color: ${props => props.theme.color.uiQuaternary};
      font-family: ${props => props.theme.fonts.fontBold};
      margin-bottom: 1rem;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        margin-bottom: 0.75rem;
      }
      a {
        font-family: ${props => props.theme.fonts.fontPrimary};
        color: ${props => props.theme.color.black};
      }
    }
    .has_category {
      margin-top: 40px;
    }
  `;

  const fetchResults = async url => {
    const res = await axios.get(url);
    const data = res.data;
    return data;
    //   setFilters(() => filters);
  };

  const mapUrltoState = () => {
    for (let [p0, p1] of receivedSearchParms) {
      if (p0 === "keywords") {
        setSearchTerm(p1);
      }
    }
  };

  const parseUrl = () => {
    if (receivedSearchParms) {
      mapUrltoState();
    }
  };

  // const { response, error } = useFetch({ url: searchapi });

  useEffect(() => {
    parseUrl();
    childrenelement && createChild();
    predictivesearchapi && getPredictiveSearch().catch(err => axiosError(err));
    const getData = async () => {
      const enabledLettersSet = new Set();
      const { Items } = await fetchResults(searchapi);
      Items.sort(function(a, b) {
        var TitleA = a.Title.toLowerCase(),
          TitleB = b.Title.toLowerCase();
        if (TitleA < TitleB)
          //sort string ascending
          return -1;
        if (TitleA > TitleB) return 1;
        return 0; //default return value (no sorting)
      });
      Items.forEach((item, i) => {
        const prev = Items[i - 1] ? Items[i - 1].Title[0].toUpperCase() : "";
        const currentLetter = item.Title[0].toUpperCase();
        enabledLettersSet.add(currentLetter.toLowerCase());
        if (currentLetter === prev) {
          return;
        } else {
          item.categoryLetter = currentLetter;
        }
      });
      setSearchData(Items);
      setEnabledLetters([...enabledLettersSet]);
    };
    searchapi && getData();
  }, []);

  useEffect(() => {
    if (selectedLetter) {
      setSearchTerm("");
      setSearchDataWithQuery({});
    }

    uiElement &&
      uiElement.current &&
      window.scrollTo(0, uiElement.current.offsetTop);
  }, [selectedLetter]);

  useEffect(() => {
    if (!searchTerm) {
      return;
    }

    const getData = async () => {
      const data = await fetchResults(
        `${searchapi}?keywords=${encodeURIComponent(searchTerm)}`
      );
      setSearchDataWithQuery(data);
      setSelectedLetter("");
    };
    getData();
  }, [searchTerm]);

  const [predictiveSearch, setPredictiveSearch] = useState([]);
  const [cmsContent, setCmsContent] = useState(null);

  const onSubmitHandler = value => {
    if (searchapi) {
      setSearchTerm(value);
    } else {
      window.location.href = `${redirect}${value}`;
    }
  };

  const createChild = () => {
    const el = document.querySelector(`#${childrenelement}`);
    if (el) {
      const obj = { __html: el.innerHTML };
      setCmsContent(obj);
    }
  };

  const getPredictiveSearch = async () => {
    const res = await axios.get(predictivesearchapi);
    const data = res.data;
    setPredictiveSearch(() => data);
  };

  const UI = (() => {
    if (
      searchTerm &&
      searchDataWithQuery.Items &&
      searchDataWithQuery.Items.length
    ) {
      return (
        <section className="grid-4 ">
          <div className="grid-4-center site-container">
            <div className="grid-right-rail flex-start">
              <div className="grid-right-rail-main__center">
                <div ref={uiElement}>
                  <div role="alert" className="a11y-hidden">
                    Showing results for {searchTerm}
                  </div>
                  <h2>
                    Showing results for <strong>{searchTerm}</strong>
                  </h2>
                  <ul>
                    {searchDataWithQuery.Items.map(
                      ({ Title, SecondaryButtonUrl, ID }) => (
                        <li key={ID}>
                          <a href={SecondaryButtonUrl}>{Title}</a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }

    if (
      searchTerm &&
      searchDataWithQuery.Items &&
      !searchDataWithQuery.Items.length &&
      searchDataWithQuery.NoResultMessage
    ) {
      return (
        <section className="grid-4 ">
          <div className="grid-4-center site-container">
            <div className="grid-right-rail flex-start">
              <div className="grid-right-rail-main__center">
                <div role="alert" className="a11y-hidden">
                  {" "}
                  {searchDataWithQuery.NoResultMessage}
                </div>
                <h2 ref={uiElement}>{searchDataWithQuery.NoResultMessage}</h2>
              </div>
            </div>
          </div>
        </section>
      );
    }
    if (searchData.length) {
      return (
        <section className="grid-4 ">
          <div className="grid-4-center site-container">
            <div className="grid-right-rail flex-start">
              <div className="grid-right-rail-main__center">
                <div ref={uiElement}>
                  {selectedLetter === "all" || !selectedLetter.length ? (
                    <>
                      <div role="alert" className="a11y-hidden">
                        Showing All Results
                      </div>
                      <h2>
                        Showing <span className="font__bold">All Results</span>
                      </h2>
                    </>
                  ) : (
                    <>
                      <div role="alert" className="a11y-hidden">
                        Showing results for {selectedLetter.toUpperCase()}
                      </div>
                      <h2>
                        Showing results for{" "}
                        <span className="font__bold">
                          {selectedLetter.toUpperCase()}
                        </span>
                      </h2>
                    </>
                  )}
                  <ul>
                    {searchData
                      .filter(({ Title }) =>
                        Title.toLowerCase().startsWith(
                          selectedLetter === "all" || !selectedLetter.length
                            ? ""
                            : selectedLetter
                        )
                      )
                      .map(
                        (
                          { Title, SecondaryButtonUrl, ID, categoryLetter },
                          index
                        ) => (
                          <li
                            key={ID}
                            className={
                              (selectedLetter === "all" ||
                                !selectedLetter.length) &&
                              index > 0 &&
                              categoryLetter
                                ? "has_category"
                                : null
                            }
                          >
                            {(selectedLetter === "all" ||
                              !selectedLetter.length) && (
                              <div className="category_letter">
                                {categoryLetter}
                              </div>
                            )}
                            <a
                              className="link-black-thin"
                              href={SecondaryButtonUrl}
                            >
                              {Title}
                            </a>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <div className="loading" ref={uiElement}>
          <span className="a11y-hidden">loading</span>
          <LoadingIcon />
        </div>
      );
    }
  })();

  const loadFunc = pg => {
    setHasMore(false);
    setLoadPage(pg);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <RemoteSearchBox>
          <SearchBox
            searchTerm={searchTerm}
            onSubmitHandler={onSubmitHandler}
            placeholder={searchboxtextplaceholder}
            suggestions={predictiveSearch.map(item => ({ label: item }))}
          />
          {/* </form> */}
          {cmsContent && <p dangerouslySetInnerHTML={cmsContent}></p>}
        </RemoteSearchBox>
        {searchapi ? (
          <>
            <div className="find_a_service_UI_container">
              <AlphabetPicker
                enabledLetters={enabledLetters}
                selectedLetter={selectedLetter}
                setSelectedLetter={setSelectedLetter}
              />
            </div>
            <div className="has-padding__bottom has-padding__top find_a_service_results_container">
              {UI}
            </div>
          </>
        ) : null}
      </Container>
    </ThemeProvider>
  );
};

export default FindAService;
