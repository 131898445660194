import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "@emotion/styled";
import { Hamburger, ActionLinkWithIcon } from "../../components";

const Button = styled.button`
  background: none;
  max-width: 296px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 10px auto;
  cursor: "pointer";
  ${props => props.theme.breakpoint.laptop} {
    max-width: 100%;
    margin: 0;
  }
  &.icon {
    border: none;
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  ${props => props.theme.breakpoint.laptop} {
    flex-direction: row;
  }
  .text-spacer {
    padding: 0 1rem;
  }
  &.mobile-event {
    margin-bottom: 32px;
  }
`;

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      // display: "flex"
    },
    drawerPaper: {
      paddingTop: props.menuType === "mobile" ? "105px" : "0"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,

        flexShrink: 0
      }
    },
    content: {
      // flexGrow: 1,
      background: `#ffffff`,
      position: "relative",
      padding: "15px",
      maxWidth: "100vw"

      // minHeight: "calc(100% - 100px)"
      // padding: theme.spacing(3)
    },
    button: {
      cursor: "pointer"
    }
  }));

  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
    document.body.classList.toggle('mobile-menu-open', !mobileOpen);
  }

  // const buttonContent = props.isMobileMenu?"yes":"no";
  // props.buttonContent.active = mobileOpen;
  return (
    <div className={classes.root}>
      <ButtonContainer className={props.extraClass && ` ${props.extraClass}`}>
        <div>
      <Button
        type="button"
        color="inherit"
        aria-label="click here to access filters"
        edge="start"
        onClick={handleDrawerToggle}
        className={
          props.menuType === "filter"
            ? `c-button c-button__secondary`
            : `icon` 
        }
      >
        {props.menuType === "mobile" && <Hamburger active={!mobileOpen} />}
        {props.menuType === "filter" && props.buttonContent}
      </Button>
      </div>
		{ props.menuType !== "mobile" &&
      <div>
        {props.searchType != "doctor" && <><span className="text-spacer">or</span> 
            <ActionLinkWithIcon
            action={props.resetHandler}
            text="Reset your search"
            name=""
          >
            {" "}
          </ActionLinkWithIcon></> }
      </div>
		}
      </ButtonContainer>

      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <div className={classes.content}>
            {props.children(handleDrawerToggle)}
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}

export default ResponsiveDrawer;
