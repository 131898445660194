import React, { useState, Fragment, useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
//import theme from "../../../../theme";
import theme from "../../../theme";
import useWindowSize from "../../hooks/useWindowSize";
import {
  Hamburger,
  ResponsiveDrawer,
  SearchBox,
  DetailsSummary,
} from "../../components";
import MobileContainer from "../../styledComponents/MobileContainer";
import { MobileLinkBlock } from "../../components";
import Axios from "axios";

const MobileMenu = ({ navigationapi, searchurl }) => {
  // const [menuActive, setMenuActive] = useState(true);
  const size = useWindowSize();
  const [menu, setMenu] = useState({});

  const onSubmitHandler = (value) => {
    window.location.href = `${searchurl}${value}`;
  };

  const menuUI = [];

  if (menu.LeftSide) {
    menu.LeftSide;
  }

  useEffect(() => {
    const catchError = (err) => {
      console.error(err);
    };
    const fetchData = async (url) => {
      const res = await Axios.get(url);

      setMenu(res.data);
    };
    fetchData(navigationapi).catch(catchError);
  }, [navigationapi]);
  return (
    <ThemeProvider theme={theme}>
      {size.width < 1023 ? (
        <>
          <ResponsiveDrawer menuType="mobile">
            {() => {
              return (
                <MobileContainer>
                  <SearchBox
                    searchTerm={""}
                    onSubmitHandler={onSubmitHandler}
                    placeholder={"search"}
                    suggestions={[].map((item) => ({ label: item }))}
                  />

                  {menu.LeftSide && <MobileLinkBlock links={menu.LeftSide} />}
                  {menu.RightSide && <MobileLinkBlock links={menu.RightSide} />}
                  {menu.RightSide && (
                    <MobileLinkBlock links={menu.UtilityNav} />
                  )}
                </MobileContainer>
              );
            }}
          </ResponsiveDrawer>
        </>
      ) : (
        <SearchBox
          searchTerm={""}
          onSubmitHandler={onSubmitHandler}
          placeholder={"search"}
          suggestions={[].map((item) => ({ label: item }))}
        />
      )}
    </ThemeProvider>
  );
};

export default MobileMenu;

// {size.width < 1023 && !showGuide ? (
//   <ResponsiveDrawer buttonContent={<FilterButtonContent />}>
