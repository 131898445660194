import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  margin-bottom: 1rem;
  button {
    cursor: pointer;
  }
`;

const CloseButton = props => {
  return (
    <Container>
      <button
        style={{ border: "none", background: "none" }}
        onClick={props.onClickHandler}
        type="button"
      >
        {props.children}
      </button>
    </Container>
  );
};
export default CloseButton;
