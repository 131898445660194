import React, { useState, useEffect } from "react";
import { ActionLinkWithIcon, DateInput } from "../../components";
import { Button, ScreenReaderText, MarginedBox } from "../../styledComponents";

// import Chevron from "../../components/Chevron";

const DatePickerUI = ({
  lastEntry,
  validDate,
  setValidDate,
  dateParam,
  setDateParam
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(false);

  const getDateString = () => {
    const obj = {};
    const d = new Date();
    obj.currentDate = `${("0" + (d.getMonth() + 1)).slice(-2)}/${(
      "0" + d.getDate()
    ).slice(-2)}/${d.getFullYear() - 2000}`;
    d.setDate(d.getDate() + 7);
    obj.nextWeek = `${("0" + (d.getMonth() + 1)).slice(-2)}/${(
      "0" + d.getDate()
    ).slice(-2)}/${d.getFullYear() - 2000}`;
    return obj;
  };

  const handleSetDate = e => {
    const actionType = e.currentTarget.name;
    const { currentDate, nextWeek } = getDateString();
    e.preventDefault();
    let end = "";
    if (actionType === "all") {
      end = lastEntry;
    }
    if (actionType === "week") {
      end = nextWeek;
    }

    setValidDate(() => ({
      start: currentDate,
      end
    }));

    setAutoSubmit(true);
  };

  useEffect(() => {
    if (autoSubmit && validDate.start && validDate.end) {
      onSubmitHandler();
      setAutoSubmit(false);
    }
  }, [autoSubmit, validDate]);

  const onSubmitHandler = e => {
    e && e.preventDefault();
    if (!validDate.start || !validDate.end) {
      setShowErrors(true);
    } else {
      let param = "";
      if (validDate.start && validDate.end) {
        const { start, end } = validDate;
        const s = start.split("/");
        const e = end.split("/");
        param = `start=20${s[2]}${s[0]}${s[1]}000000&end=20${e[2]}${e[0]}${
          e[1]
        }000000`;
      }

      if (dateParam !== param) {
        setDateParam(() => param);
      }
    }
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <div className="inner">
        <span>From</span>
        <div className="text-input">
          <label htmlFor="dateFrom">
            <ScreenReaderText>Enter date from</ScreenReaderText>
          </label>
          <DateInput
            setValidDate={setValidDate}
            validDate={validDate}
            name="start"
            id="zipcode-ui-zipcode"
            parentState={validDate.start}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            focus={!validDate.start}
          />
        </div>
        <span>To</span>
        <div className="text-input">
          <label htmlFor="dateTo">
            <ScreenReaderText>Enter date to</ScreenReaderText>
          </label>
          <DateInput
            setValidDate={setValidDate}
            validDate={validDate}
            name="end"
            id="zipcode-ui-zipcode"
            parentState={validDate.end}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            focus={validDate.start && !validDate.end}
          />
        </div>
        <Button type="submit">go</Button>
      </div>
      <div>
        <div>
          <div className="link-container">
            <MarginedBox>
              <ActionLinkWithIcon
                action={handleSetDate}
                text="All Dates"
                name="all"
              >
                {" "}
                <i className="fa-regular fa-calendar-days"></i>
              </ActionLinkWithIcon>
            </MarginedBox>

            <MarginedBox>
              <ActionLinkWithIcon
                action={handleSetDate}
                text="This Week"
                name="week"
              >
                {" "}
                <i className="fa-regular fa-calendar-days"></i>
              </ActionLinkWithIcon>
            </MarginedBox>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DatePickerUI;
