import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	useCallback,
} from 'react';

import { DayPicker, defaultDateLib } from 'react-day-picker';

import ActionLinkWithIcon from '../ActionLinkWithIcon';

const convertDate = (dateToCovert) => {
	if (dateToCovert === undefined || dateToCovert === null) return undefined;

	const split = dateToCovert.split('/');

	if (split.length < 3) {
		return undefined;
	}

	const year = Number('20' + split[2]);
	const monthIndex = Number(split[0]) - 1;
	const date = Number(split[1]);

	const newDate = defaultDateLib.newDate(year, monthIndex, date);

	return newDate;
};

const formatFromTo = (dateRange) => {
	if (dateRange === undefined || dateRange?.from === undefined) {
		return `Select a date range`;
	}

	let from = defaultDateLib.format(dateRange.from, 'MMM d, yyyy');
	let to = defaultDateLib.format(dateRange.to, 'MMM d, yyyy');

	const isSameMonth = defaultDateLib.isSameMonth(
		dateRange?.from,
		dateRange?.to
	);

	const isSameYear = defaultDateLib.isSameYear(
		dateRange?.from,
		dateRange?.to
	);
	const isSameDay = defaultDateLib.isSameDay(dateRange?.from, dateRange?.to);

	if (dateRange?.to === undefined || isSameDay) {
		return from;
	}

	if (isSameMonth && isSameYear) {
		from = defaultDateLib.format(dateRange.from, 'MMM d');
		to = defaultDateLib.format(dateRange.to, 'd, yyyy');
	}

	if (!isSameMonth && isSameYear) {
		from = defaultDateLib.format(dateRange.from, 'MMM d');
		to = defaultDateLib.format(dateRange.to, 'MMM d, yyyy');
	}

	return `${from} – ${to}`;
};

const getDateParamsInUrl = () => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	const output = {
		start: undefined,
		end: undefined,
	};
	if (params.length !== 0) {
		params.forEach((val, key) => {
			if (key === 'start' || key === 'end') {
				// Taken from EventSearchContainer, apologies to me in the future.
				// prettier-ignore
				const date = `${val.substring(4, 6)}/${val.substring(6, 8)}/${val.substring(2,4)}`;
				output[key] = convertDate(date);
			}
		});
	}
	// translate for the date picker
	return {
		from: output.start,
		to: output.end,
	};
};

const DayPickerUI = ({ setValidDate, lastEntry }) => {
	const lastEntryDate = useMemo(() => convertDate(lastEntry), [lastEntry]);
	const today = useMemo(() => defaultDateLib.today(), [lastEntry]);

	const buttonRef = useRef();
	const dialogRef = useRef();

	const [open, setOpen] = useState(false);
	const [buttonValue, setButtonValue] = useState('');
	const [selectedDateRange, setSelectedDateRange] = useState(() =>
		getDateParamsInUrl()
	);

	const hasValidDate =
		selectedDateRange?.from !== undefined ||
		selectedDateRange?.to !== undefined;
	/**
	 * Handles focusing on the button input.
	 */
	const handleFocusButton = useCallback(() => {
		if (buttonRef?.current !== undefined) {
			buttonRef.current.focus();
		}
	}, [buttonRef]);

	/**
	 * Handles any clicks outside of the dialog and closes it.
	 *
	 * @param {React.EventHandler} e
	 */
	const handleClickOutsideDialog = useCallback(
		(e) => {
			if (
				open &&
				e.target !== buttonRef.current &&
				dialogRef.current &&
				!dialogRef.current.contains(e.target)
			) {
				setOpen(() => false);
				handleFocusButton();
			}
		},
		[open, dialogRef, buttonRef]
	);

	/**
	 * Handles listening to escape key presses and closes the dialog.
	 *
	 * @param {React.EventHandler} e
	 */
	const handleEscape = useCallback((e) => {
		if (e.key === 'Escape') {
			setOpen(() => false);
			handleFocusButton();
		}
	}, []);

	/**
	 * Handles updating the parent state when a new date range is selected.
	 * In addition, updates the input value.
	 *
	 */
	useEffect(() => {
		const format = 'MM/dd/yy';
		const update = {
			start:
				selectedDateRange?.from !== undefined
					? defaultDateLib.format(selectedDateRange.from, format)
					: '',
			end:
				selectedDateRange?.to !== undefined
					? defaultDateLib.format(selectedDateRange.to, format)
					: '',
		};
		setValidDate(update);

		const updateInputValue = formatFromTo(selectedDateRange);
		setButtonValue(() => updateInputValue);
	}, [selectedDateRange?.to, selectedDateRange?.from]);

	/**
	 * Handles attempting to focus on the current from day when a user opens the dialog.
	 *
	 */
	useEffect(() => {
		if (!open) return;

		const from =
			selectedDateRange?.from !== undefined
				? selectedDateRange.from
				: today;

		const parsedSelectedDate = defaultDateLib.format(from, 'yyyy-MM-dd');

		const selectedCalendarDayEl = document.querySelector(
			`[data-day="${parsedSelectedDate}"] button`
		);
		if (
			selectedCalendarDayEl &&
			selectedCalendarDayEl instanceof HTMLElement
		) {
			selectedCalendarDayEl.focus({
				focusVisible: true,
				preventScroll: false,
			});
		}
	}, [open]);

	/**
	 * Handles initializing the event listeners for escape and click outside dialog
	 */
	useEffect(() => {
		document.addEventListener('keydown', handleEscape, false);
		document.addEventListener('mousedown', handleClickOutsideDialog, false);

		return () => {
			document.removeEventListener('keydown', handleEscape, false);
			document.removeEventListener(
				'mousedown',
				handleClickOutsideDialog,
				false
			);
		};
	}, [open]);

	/**
	 * The Output of the component
	 */
	return (
		<div className="daypickerui">
			<div className="daypickerui--grid">
				<label className="daypickerui--label" htmlFor="daterange">
					Date Range
				</label>
				<input
					className={`daypickerui--selector ${
						hasValidDate && 'has-valid-date'
					}`}
					type="button"
					id="daterange"
					name="daterange"
					onClick={() => setOpen(true)}
					ref={buttonRef}
					value={buttonValue}
					readOnly
				/>
			</div>
			<dialog ref={dialogRef} open={open} className="daypickerui--dialog">
				<DayPicker
					mode="range"
					autoFocus={true}
					selected={selectedDateRange}
					onSelect={setSelectedDateRange}
					showOutsideDays={false}
					startMonth={today}
					endMonth={lastEntryDate}
					timeZone="America/New_York"
					disabled={{ before: today, after: lastEntryDate }}
				/>
				<div className="daypickerui--dialog-close">
					<ActionLinkWithIcon
						text="Close"
						action={() => setOpen(false)}
					/>
				</div>
			</dialog>

			<div className="daypickerui--preselect">
				<ActionLinkWithIcon
					text="All Dates"
					onFocus={() => setOpen(false)}
					action={() =>
						setSelectedDateRange({
							from: today,
							to: lastEntryDate,
						})
					}
				>
					<i className="fa-regular fa-calendar-days"></i>
				</ActionLinkWithIcon>
				<ActionLinkWithIcon
					text="This Week"
					onFocus={() => setOpen(false)}
					action={() =>
						setSelectedDateRange({
							from: defaultDateLib.startOfWeek(today),
							to: defaultDateLib.endOfWeek(today),
						})
					}
				>
					<i className="fa-regular fa-calendar-days"></i>
				</ActionLinkWithIcon>
			</div>
		</div>
	);
};

export default DayPickerUI;
