import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "../../../theme";
import useWindowSize from "../../hooks/useWindowSize";
import searchConditional from "../../helpers/searchConditional";
import axios from "axios";
import styled from "@emotion/styled";
import Pagination from "rc-pagination";
import locale from "rc-pagination/es/locale/en_US";
import "rc-pagination/assets/index.less";

import {
  SearchBox,
  RemoteSearchBox,
  ShowingResultsMessage,
  FlexRow,
  Spacer,
  LoadingIcon,
} from "../../components";

const PressReleaseNew = ({
  searchapi,
  searchtype,
  searchboxtextplaceholder,
  predictivesearchapi = "",
}) => {
  const size = useWindowSize();
  const ref = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [didMount, setDidMount] = useState(false);
  const [stateMapped, setStateMapped] = useState(false);
  const [receivedSearchParams, setSearchParams] = useState(
    new URLSearchParams(location.search.toLowerCase()),
  );

  // search box breaks if there isn't a predictivesearch array
  const predictiveSearch = [];

  const Card = styled.div`
    display: block;
    border: 1px solid ${(props) => props.theme.color.greyDark};
    box-shadow: ${(props) => props.theme.boxShadow};
    margin-bottom: 20px;
    .date {
      padding: 10px 13px;
      background: ${(props) => props.theme.color.grey};
      font-size: 14px;
    }
    .link {
      padding: 15px 13px;
      a {
        font-size: 20px;
      }
      + .date {
        text-align: right;
      }
    }
  `;

  const onSubmitHandler = (value) => {
    document.activeElement && document.activeElement.blur();
    setSearchTerm(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (location.href.indexOf("?" != -1)) {
      mapStateToUrl();
    }
    setDidMount(true);
  }, []);

  const mapStateToUrl = () => {
    if (receivedSearchParams != "") {
      for (let [p0, p1] of receivedSearchParams) {
        if (p0 === "keywords") {
          setSearchTerm(p1);
        } else if (p0 === "page") {
          setCurrentPage(parseInt(p1));
        }
      }
    }
    setStateMapped(true);
  };

  const buildSearchUrl = () => {
    let params = [];
    searchTerm && params.push(`keywords=${searchTerm}`);
    currentPage && params.push(`page=${currentPage}`);
    if (params.length) {
      let paramsString = `${params.join("&")}`;
      window.history.pushState("", "", "?" + paramsString);
    } else {
      window.location = window.location.pathname;
    }
  };

  const changePage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    if (didMount) {
      buildSearchUrl();
      const getResults = async () => {
        setResults(() => {
          return {};
        });
        setLoading(true);

        const res = await axios
          .get(searchapi, {
            cancelToken: source.token,
            params: {
              keywords: searchTerm,
              page: currentPage || 1,
            },
          })
          .then((res) => {
            if (res.status >= 200 && res.status <= 299) {
              const results = res.data;
              const headers = res.headers;
              setResults(() => {
                return results;
              });
              setTotalResults(() => {
                return headers["x-pagination-total"];
              });
              setPageSize(() => {
                return headers["x-pagination-pagesize"];
              });
            }
          })
          .catch(function (error) {
            if (axios.isCancel(error)) {
              //console.log('request cancelled: ' + error);
            } else {
              console.error(error);
            }
          });
        if (currentPage > 1 && ref.current !== undefined) {
          ref.current.setAttribute("tabIndex", 0);
          ref.current.focus();
        }

        setLoading(false);
      };
      getResults();
    }
    return () => {
      source.cancel();
    };
  }, [currentPage, searchTerm, stateMapped]);

  const Results = () => {
    return results.map((item, i) => (
      <Card key={i}>
        <div className="date">
          <span className="">
            {" "}
            <i className="fa-regular fa-calendar-days"></i>
            <span>Posted date: {item.posteddate}</span>
          </span>
        </div>
        <div className="link">
          <a className="link-black-thick" href={item.url}>
            {item.title}
          </a>
        </div>
        <div className="date">
          <span className="">
            {" "}
            <i className="fa-regular fa-calendar-days"></i>
            <span>Event date: {item.eventdate}</span>
          </span>
        </div>
      </Card>
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <section className="grid-4">
        <RemoteSearchBox>
          <SearchBox
            searchTerm={searchTerm}
            onSubmitHandler={onSubmitHandler}
            placeholder={searchboxtextplaceholder}
            suggestions={predictiveSearch.map((item) => ({ label: item }))}
          />
        </RemoteSearchBox>
        <div className="grid-4-center site-container">
          <div className="grid-right-rail flex-start">
            <div className="grid-right-rail-main__center has-padding__top has-padding__bottom">
              <FlexRow hasBorder>
                <ShowingResultsMessage
                  loading={loading}
                  msg={searchConditional(searchtype).showingResultsMessage}
                  data={results}
                  searchTerm={searchTerm}
                  searchType={searchtype}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalResults={totalResults}
                  noresults={"No results found."}
                />
                <Spacer />
              </FlexRow>
              <div ref={ref}>
                {results && results.length && !loading ? <Results /> : null}
                {loading ? <LoadingIcon /> : null}
              </div>
              {results && results.length && !loading ? (
                <Pagination
                  locale={locale}
                  className="ant-pagination"
                  onChange={changePage}
                  current={currentPage}
                  total={totalResults}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default PressReleaseNew;
