import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";

// const DoctorGuidedSearch = ({ buckets, bucketClicked, inpatient, outpatient, specialtyClicked, searchbucketapi }) => {
  const DoctorGuidedSearch = ({ searchbucketapi, outpatientapi, onbucketclicked, onspecialtyclicked, specialtyheading, bucketheading }) => {
  const [buckets, setBuckets] = useState({});
  const [outpatient, setOutpatient] = useState({});
  
  useEffect(() => {
    getBuckets();
    getOutpatient();
  },[]);

  const getBuckets = async () => {
    try {
      const bucketRes = await axios.get(searchbucketapi, {});
      if (bucketRes.status >= 200 && bucketRes.status <= 299)
      {
        const bucketResults = bucketRes.data;
        setBuckets(() => {
          return bucketResults;
        });
      }
    }
    catch(error) {
      console.log(error);
    }
  }

  const getOutpatient = async () => {
    try {
      const outpatientRes = await axios.get(outpatientapi, {});
      if (outpatientRes.status >= 200 && outpatientRes.status <= 299)
      {
        const outpatientResults = outpatientRes;
        setOutpatient(() => {
          return outpatientResults.data;
        });
      }
    }
    catch(error) {
      console.log(error);
    }
  };

  const UI = buckets.length ? (buckets.map(
    ({ leftimage, rightimage, cssclass, title, filtercodename, filters }) => (
      <li className={`c-media-card is-box ${cssclass}`} key={title}>
        <button
          className="c-direction-aware-overlay"
          style={{ backgroundImage: `url("${leftimage}")` }}
          onClick={e => onbucketclicked(filtercodename, e.currentTarget.value) }
          value={filters[0].newValue}
        >
          <h2>{title}</h2>
          <div className="c-media-card-image">
            <img src={rightimage} alt={title} />
          </div>
          <span className="c-direction-aware-overlay-container"><span className="c-direction-aware-overlay-overlay"></span></span>
        </button>
      </li>
    )
  )) : null;

  const outpatientUI = outpatient.length ? (outpatient.map(
    ({ text, url }) => (
      <li style={{marginBottom: '20px'}} key={text.replace(' ', '-')}>
        <button
          className="c-text-routing-card padding-reset"
          type="button"
          onClick={e => onspecialtyclicked(text.toLowerCase()) }
        >
          {text}
        </button>
      </li>
    )
  )) : null;

  return (
    <>
      <section className="grid-4">
        <div className="grid-4-center site-container">
          <div className="grid-right-rail__wrapper ">
            <div className="grid-right-rail">
              <div className="grid-right-rail-main__full is-dashboard-reset">
                <div className="c-media-card__wrapper is-box has-padding__bottom c-side-dashboard c-side-dashboard-wide">
                {bucketheading && (<h2 className="text-align-center">{bucketheading}</h2>)}
                  <ul className="list-rest">
                    {UI}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="grid-4">
        <div className="grid-4-center site-container">
          <div className="grid-right-rail flex-start">
            <div className="grid-right-rail-main__full">
              {specialtyheading && (<h2 className="text-align-center">{specialtyheading}</h2>)}
              <div className="c-column__list-2 has-padding__top mobile-reset">
                <ul className="c-list-2 list-reset c-text-routing is-text-routing-reset">
                  {outpatientUI}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorGuidedSearch;
