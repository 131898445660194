import React from "react"
import styled from "@emotion/styled";

const Container = styled.div`
   display: block;
`;

const TopDoctor = props => {
  return (
    <Container style={props.style}>
      {props.topdoctor.topdoctoricon != '' &&
        <span style={{color:'#78B336', marginRight:'.5rem'}}><i className={`fa fa-solid ${props.topdoctor.topdoctoricon}`}></i></span>
      }

      {(props.topdoctor.topdoctortext !== '' && props.topdoctor.topdoctorurl !== '') ? <a href={props.topdoctor.topdoctorurl} target="_blank">{props.topdoctor.topdoctortext}</a>
                                                                                     : props.topdoctor.topdoctortext
      }
    </Container>
  );
}

export default TopDoctor;
