import React from "react";
import styled from "@emotion/styled";

const Row = styled.div`
  display: flex;

  &.has-border {
    border-bottom: 1px solid ${props => props.theme.color.greyDark};
  }

  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: flex-start;
  &.flex_end {
    align-items: flex-end;
  }
  > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    margin-bottom: 1rem;
    margin-top: 0;
    line-height: 1.2;
    max-width: 100%;
  }
  .spacer {
    flex-grow: 100;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  ul {
    line-height: 1.2;
  }
`;

const FlexRow = props => {
  const classNames = [];
  props.hasBorder && classNames.push("has-border");
  props.flex_end && classNames.push("flex_end");
  return <Row className={classNames.join(" ")}>{props.children}</Row>;
};

export default FlexRow;
