import React from "react";
import styled from "@emotion/styled";

const Button = styled.button`
  font-weight: bold;
  color: ${props => props.theme.color.uiSecondary};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.12px;
  line-height: 17px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  // align-self: center;
  /* margin: 1rem 2rem 0 2rem; */
  transition: all 200ms ease;
  .text {
    background-image: repeating-linear-gradient(
      0deg,
      ${props => props.theme.color.uiSecondaryActive},
      ${props => props.theme.color.uiSecondaryActive} 2px,
      transparent 2px,
      transparent 2em
    );
    background-size: 0% 100%;
    background-repeat: no-repeat;
    transition: all 200ms ease;
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.color.uiSecondaryActive};
    .text {
      background-size: 100% 100%;

      transition: all 200ms ease;
    }
  }
`;

const keyaction = (e, action) => {
  if (e.key === 'Enter') {
    action();
  }
};

const ActionLinkWithIcon = (props) => {
  const { action, text, children, name, onFocus } = props;
  
  return (
    <Button onClick={action} onKeyPress={(e) => keyaction(e, action)} name={name} type="button" onFocus={onFocus}>
      {children}
      <span className="text">{text}</span>
    </Button>
  );
};

export default ActionLinkWithIcon;
