import React, { memo, Fragment } from "react";
import styled from "@emotion/styled";
// import { Loader } from "../../components";

// console.log(H.MobileLink);

const Container = styled.div`
  font-size: 18px;
  ${props => props.theme.breakpoint.mobileonly} {
    font-size: 16px;
    .hide-for-mobile {
      display: none;
    }
  }
  &.visibly-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  ${ props => props.theme.breakpoint.mobileonly} {
    p.showing-results {
      &.doctor {
        font-size: 16px;
        margin-top: 10px !important;
      }
    }
}
`;

const areEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

const ShowingResultsMessage = memo(
  ({ data, msg, loading, searchType, searchTerm, visibilyHidden, currentPage=1, pageSize=1, totalResults=1, noresults="" }) => {
    let displayString;

    if (searchType == "doctor" || searchType == "location")
    {
      if (data && data.length)
      {
        var msg1 = currentPage == 1 ? `1 - ${data.length}` : `${(((currentPage-1)*pageSize)+1)} - ${(((currentPage-1)*pageSize) + data.length)}`;
        displayString = (
          <p id="results" className={`showing-results ${searchType}`} aria-live="polite" aria-atomic="true">
            <span className="hide-for-mobile">Showing </span><strong>{msg1} of {totalResults}</strong> {msg}</p>
        );
      }
      else if (loading) {
        displayString = (<p id="results" className="showing-results" aria-live="polite" aria-atomic="true">
            Loading ...</p>
        );
      }
      else {  
        displayString = noresults;
      }
    }
    else if (searchType == "event")
    { 
      if (data && data.groupingfields && data.groupingfields.length > 0)
      {
        var eventCount = 0;
        for (var gf = 0; gf < data.groupingfields.length; gf++)
        {
          eventCount += data.groupingfields[gf].events.length;
        }
        var msg1 = currentPage == 1 ? `1 - ${eventCount}` : `${(((currentPage-1)*pageSize)+1)} - ${(((currentPage-1)*pageSize) + eventCount)}`;
        displayString = (
          <p id="results" className="showing-results" aria-live="polite" aria-atomic="true">
            <span className="hide-for-mobile">Showing </span><strong>{msg1} of {totalResults}</strong> {msg}</p>
        );
      }
      else if (loading) {
        displayString = (<p id="results" className="showing-results" aria-live="polite" aria-atomic="true">
            Loading ...</p>
        );
      }
      else {  
        displayString = noresults;
      }
    }
    else if (searchType == "global") 
    {
      if (data && data.length) 
      {
        var msg1 = currentPage == 1 ? `1 - ${data.length}` : `${(((currentPage-1)*pageSize)+1)} - ${(((currentPage-1)*pageSize) + data.length)}`;
        displayString = (
          <span>
            <span className="hide-for-mobile">Showing </span>
            <strong>{`${msg1} of ${totalResults} `}</strong>
            <span>{`${msg}${
              searchType === "global" && searchTerm ? " for " + searchTerm : ""
            }`}</span>
          </span>
        );
      }
      else if (loading) 
      {
        displayString = (<p id="results" className="showing-results" aria-live="polite" aria-atomic="true">
            Loading ...</p>
        );
      }
      else 
      {  
        displayString = noresults;
      }
    }
    else if (searchType == "press_release") {
      if (data && data.length) {
        var msg1 = currentPage == 1 ? `1 - ${data.length}` : `${(((currentPage-1)*pageSize)+1)} - ${(((currentPage-1)*pageSize) + data.length)}`;
        displayString = (
          <span>
            <span className="hide-for-mobile">Showing </span>
            <strong>{`${msg1} of ${totalResults} `}</strong>
            <span>{`${msg}${
              searchType === "global" && searchTerm ? " for " + searchTerm : ""
            }`}</span>
          </span>
        );
      } else {
        displayString = noresults;
      }
    }
    else if (!loading && (data.Items && data.Items.length)) {
      displayString = (
        <span>
          <span className="hide-for-mobile">Showing </span>
          <strong>{`${data.Items.length} of ${data.NumOfResults} `}</strong>
          <span>{`${msg}${
            searchType === "global" && searchTerm ? " for " + searchTerm : ""
          }`}</span>
        </span>
      );
    } else {
      displayString = data.NoResultMessage;
    }

    const UI = (
      <Container className={visibilyHidden ? "visibly-hidden" : ""}>
        {loading ? (
          <span>Loading...</span>
        ) : (
          <div role="alert">{displayString}</div>
        )}
      </Container>
    );

    return UI;
  },
  areEqual
);

export default ShowingResultsMessage;
