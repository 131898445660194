import React from "react";
import styled from "@emotion/styled";
import { DetailsSummary, MobileLink } from "../../components";

const UL = styled.ul`
  border-bottom: 1px solid ${props => props.theme.color.greyDark};
  margin: 0;
  margin-bottom: 20px;
  padding: 1.5rem 0 2rem 0;

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    a {
      font-family: ${props => props.theme.fonts.fontPrimary};

      &:before,
      &:after {
        content: none;
      }
    }
  }
  &:nth-of-type(1) {
    li a {
      font-size: 16px;
      color: ${props => props.theme.color.black};
    }
  }
  &:nth-of-type(2) {
    li a {
      font-size: 14px;
      color: ${props => props.theme.color.black};
    }
  }
  &:nth-of-type(3) {
    li a {
      font-size: 12px;
      color: ${props => props.theme.color.uiSecondary};
    }
  }
  &:last-of-type {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const Link = ({
  Children,
  HasChildren,
  Target,
  Title,
  LinkText,
  LinkUrl
}) => {};

const MobileLinkBlock = ({ links }) => {
  return (
    <UL>
      {links.map((el, i) => (
        <li key={el.LinkText + i}>
          <MobileLink link={el} />
        </li>
      ))}
    </UL>
  );
};

export default MobileLinkBlock;
