import React from "react";
import styled from "@emotion/styled";

const Container = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  li {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    .toggle {
      margin-left: 20px;
      font-size: 14px;
      color: ${props => props.theme.color.uiPrimary};

      ${props => props.theme.breakpoint.mobileonly} {
        &[disabled] {
          display: none;
        }
      }
    }
    &:first-of-type {
      .toggle {
        margin-left: 0;
      }
    }
  }
`;

const ToggleLocationView = ({ view, onClickHandler }) => {
  return (
    <Container>
      <li aria-hidden={view !== "list"}>
        <button
          className={`toggle bold c-button c-button__bare link-action`}
          disabled={view !== "list"}
          onClick={() => onClickHandler("map")}
          type="button"
        >
          <i className="fa-regular fa-map"></i>
          <span>Map View</span>
        </button>
      </li>
      <li aria-hidden={view !== "map"}>
        <button
          className={`toggle bold c-button c-button__bare link-action`}
          disabled={view !== "map"}
          onClick={() => onClickHandler("list")}
          type="button"
        >
          <i className="fa-solid fa-list"></i>
          <span>List View</span>
        </button>
      </li>
    </Container>
  );
};

export default ToggleLocationView;
