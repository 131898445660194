import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
//import theme from "../../../../theme";
import theme from "../../../theme";

const rotate = keyframes`
  0% {
    transform: rotate(0deg) scale(0.8);
  
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
transform: rotate(720deg) scale(0.8);
  }
`;

const ball1 = keyframes`

  0% {
    box-shadow: 30px 0 0 ${theme.color.uiPrimary};
  }
  50% {
    box-shadow: 0 0 0 ${theme.color.uiPrimary};
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 ${theme.color.uiPrimary};
    margin-bottom: 10px;
  }
`;

const ball2 = keyframes`
  0% {
    box-shadow: 30px 0 0 ${theme.color.uiSecondary};
  }
  50% {
    box-shadow: 0 0 0 ${theme.color.uiSecondary};
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 ${theme.color.uiSecondary};
    margin-top: 0;
  }
`;

const Container = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-around;
`;

const Icon = styled.div`
  animation: ${rotate} 1500ms infinite;
  height: 50px;
  width: 50px;
  margin: 50px 0;
  opacity: 0.5;
  &:before,
  &:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
  }
  &:before {
    animation: ${ball1} 1500ms infinite;
    background-color: ${theme.color.uiSecondaryActive};
    box-shadow: 30px 0 0 ${theme.color.uiPrimary};
    margin-bottom: 10px;
  }
  &:after {
    animation: ${ball2} 1s infinite;
    background-color: ${theme.color.uiPrimaryActive};
    box-shadow: 30px 0 0 ${theme.color.uiSecondary};
  }
`;

const LoadingIcon = () => {
  return (
    <Container>
      <Icon />
    </Container>
  );
};

export default LoadingIcon;
