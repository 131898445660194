import React, { lazy, Suspense } from "react";
import { LoadingIcon } from "../../components";

const EventSearchContainer = lazy(() => import("./EventSearchContainer"));

const EventSearchContainerWithSuspense = props => {

  return (
    <Suspense fallback={<LoadingIcon />}>
      <EventSearchContainer {...props}/>
    </Suspense>
  );
};

export default EventSearchContainerWithSuspense;
