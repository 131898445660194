import React from "react";
import styled from "@emotion/styled";
import DetailsSummary from "../DetailsSummary";
import FormCheckBox from "./FormCheckBox";

const Container = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  width: 434px;
  display: block;

  ${props => props.theme.breakpoint.mobileonly} {
    width: 100%;
  }
`;

const FilterGroups = ({ filters, checkHandler, activeFilters }) => {
  return filters.map(({ FilterCodeName, FilterDisplayName, FilterItems }) => {
    return (
      <Container key={FilterCodeName}>
        <DetailsSummary
          border={true}
          padding={true}
          background={true}
          summary={FilterDisplayName}
        >
          <FormCheckBox
            fieldCodeName={FilterCodeName}
            onChangeHandler={checkHandler}
            options={FilterItems}
            legend={FilterDisplayName}
            value={activeFilters[FilterCodeName]}
          />
        </DetailsSummary>
      </Container>
    );
  });
};

export default FilterGroups;
