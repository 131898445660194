import React, { useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const EventCardForPrint = props => {
  const [showMore, setShowMore] = useState(false);
    const imgFadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`;

const Card = styled.div`
    break-inside: avoid;
    display: block;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.color.greyDark};
    box-shadow: ${props => props.theme.boxShadow};
    ${props => props.theme.breakpoint.mobileonly} {
      /* border-left: none;
      border-right: none; */
    }
    margin-bottom: 20px;
    padding: 8px;
    @media print {
      padding: 0;
    }
    ${props => props.theme.breakpoint.tablet} {
      padding: 0;
    }
    .margin-bottom {
      margin-bottom: 10px;
      &-2x {
        margin-bottom: 20px;
      }
    }

    .margin-top {
      margin-top: 10px;
    }
    
    &.flex {
      .upper-wrapper {
        display: flex;
        flex-wrap: wrap;
        @media print {
          flex-wrap: nowrap;
        }
        ${props => props.theme.breakpoint.tablet} {
          flex-wrap: nowrap;
        }
      }
      .lower-wrapper {
        padding: 0 25px 0 25px;
      }
    }
    .media {
      /* height:207.66px;
      height:202.66px; */
      width: 21%;
      flex: 0 0 21%;
       padding: 0 8px 8px 0;
      /* padding-right: 8px;
      padding-bottom:8px;
      padding-left:0;
      padding-top:100%; */
      position:relative;
      @media print {
        padding: 8px 0 8px 8px;
        /* padding-top:calc(100% + 8px);
        padding-right:0;
        padding-bottom:8px;
        padding-left:8px; */
      }
      ${props => props.theme.breakpoint.tablet} {
        padding: 8px 0 8px 8px;
        /* padding-top:calc(100% + 8px);
        padding-right:0;
        padding-bottom:8px;
        padding-left:8px; */
      }

      .responsive-card-image{
        width:100%;
        /* padding-top:100%; */
        position:relative;
        height:100%;
        background:${props => props.theme.color.grey};
      }
      .standard-image{
        /* background:${props => props.theme.color.grey};
        width:100%;
        height:auto; */
      }
      img {
        /* max-width: 100%;
        position:absolute;
        top:0;
        left:0; */
        width:100%;
        height:100%;
        object-fit:cover;
      }
      @media print {
        /* width: 200px; */
        flex: 0 1 27%;
      }
      ${props => props.theme.breakpoint.tablet} {
        /* width: 200px; */
        flex: 0 1 27%;
      }
      /* img {
        opacity:0;
        animation:fadeIn 300ms ease forwards;
      } */
      img{
        animation: ${imgFadeIn} 1000ms forwards;
      }
    }
    .text {
      padding: 25px 25px 0 25px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 35%;

      display: ${props.variant === "global" ? "flex" : "block"};
      .flex-right {
        display: ${props.variant === "global" ? "flex" : "block"};
        flex-grow: ${props.variant === "global" ? "1" : "0"};
        flex-direction: ${props.variant === "global" ? "column" : "row"};
        align-items: ${props.variant === "global" ? "flex-end" : "flex-start"};
      }
      flex-direction: column;
      h3,
      h4 {
        margin-top: 0;
        margin-bottom: 13px;
      }

      h3 + h4 {
        margin-top: -4px;
      }
      h3{
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 8px;
      }
      h4 {
        line-height: 1.2;
        font-size: 20px;
        @media print {
          font-size: 18px;
        }
      }

      
        padding: 0;
        /*margin-bottom: 1rem;*/
        ${props => props.theme.breakpoint.mobileonly} {
        h3 {
          margin-top: 0.5em;
        }
       }
      @media print {
        /* padding: 20px 9px 20px 25px; */
        padding: ${
          props.variant === "global"
            ? "20px 25px 20px 25px"
            : "20px 9px 0 25px"
        };
      }
      ${props => props.theme.breakpoint.tablet} {
        /* padding: 20px 9px 20px 25px; */
        padding: ${
          props.variant === "global"
            ? "20px 25px 20px 25px"
            : "20px 9px 20px 25px"
        };
      }
    .multiday {
      display: flex;
      flex-direction: column;
      align:  center;
      justify-content: flext-start;
    }
    .multiday p {
      margin: .5em 0 1em 0;
      color: ${props => props.theme.color.uiSecondary};
      font-size: 14px;
      font-family: 'Gotham Bold';
      letter-spacing: -.12px;
      line-height: 1.15;
      text-transform: uppercase;
    }
    .multiday h3 {
        margin-bottom: 8px;
      }
    }
    .summary-link-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        font-size: 13px;
      }
      span.inpatient-specialty {
      font-size: 70%;
      font-weight: bolder;
      margin-top: 10px;
      display: block;
      text-transform: uppercase;
    }

    span.black-thin {
      font-size: rem-calc(16px);
      text-decoration: none;
      font-size: 14px;
      letter-spacing: -0.12px;
      line-height: 1.15;
      text-decoration: none;
      position: relative;
    }
    }
    .fee {
      margin-right: 1rem;
      font-size: 14px;
      color: ${props => props.theme.color.alert};
    }
    .buttons {

      /* ${props => props.theme.breakpoint.mobileonly} {
        padding:0;
      } */
      padding:0;
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media print {
        padding: 20px 0;
        /* padding: 20px 0; */
        text-align: center;
        flex-direction: column;
        width: 235px;
        height: 100%;
      }
      ${props => props.theme.breakpoint.tablet} {
        padding: 20px 0;
        /* padding: 20px 0; */
        text-align: center;
        flex-direction: column;
        width: 235px;
        height: 100%;
      }
      .link3 {
        width: 100%;
        flex-shrink: 0;
        margin: auto 0 0 0;
        font-family: ${props => props.theme.fonts.fontBold};
        font-size:14px;
        /* color: ${props => props.theme.color.textColorSecondary};
        font-family: ${props => props.theme.fonts.fontBold}; */
        /* font-size:14px; */
      }
      

      div {
        margin-right: 15px;

        @media print {
          margin-right: 0;
          padding: 0 25px;
        }
        &:last-of-type {
          margin-right: 0;
        }
        
        ${props => props.theme.breakpoint.tablet} {
          margin-right: 0;
          padding: 0 25px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .c-button {
        ${props => props.theme.breakpoint.mobileonly} {

          svg {
            display: none;
          }
        }
        padding: 13px 0;
        padding: 12px 17px;
        text-align: center;
        text-decoration: none;
        margin-bottom: 7px !important;
        /* ${props => props.theme.breakpoint.tablet} { */
          margin-bottom: 7px;
        /* } */
        @media print {
          color: ${props => props.theme.color.greyDark}
        }
      }
    }
    .is-small {
        font-size: 14px;
        line-height: line-height: 14px;
    }
    .fax {
      margin: 15px 0 20px 0;
      font-family: 'Gotham Bold';
      font-size: 14px;
    }
    
    .location-details-wrap {
      display: flex;
    }
    .location-icon {
      .svg-wrap {
        padding: 5px;
        margin-right: 16px;
        background-color: ${props => props.theme.color.grey};
        width: 61px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        @media print {
          padding: 0;
          width: 33px;
          height: 29px;
        }
        svg
        {
          path {
            fill: ${props => props.theme.color.uiPrimary };
          }
        }
      }
    }
    .location-details-text-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .location-details {
      line-height: 19px;
      font-size: 14px;
      font-family: ${props => props.theme.fonts.fontSecondary};
      &:first-of-type {
        color: ${props => props.theme.color.uiPrimary};
        font-family: ${props => props.theme.fonts.fontBold};
        font-size: 16px;
      }
    }
     .bottom-wrapper {
      &.flex {
        justify-content: space-between;
      }
    }
  `;

  return (
    <Card className="flex">
      <div className="upper-wrapper">
        <div className="text">
          {props.ismulti && props.multidatestring ? (
            <div className="multiday">
              <span className="multiday-flag">{props.multiflagstring}</span>
              {props.multidatestring && <p>{props.multidatestring}</p>}
              {props.title && <h3>{props.title}</h3>}
            </div>
          ) : props.ismulti && !props.multidatestring && props.title ? (
              props.title && <h3>{props.title}</h3>
            )
            : props.title && <h3>{props.title}</h3> }

          {props.ismulti && !props.multidatestring && props.multiflagstring ? (
            <div className="multiday">
              <span className="multiday-flag">{props.multiflagstring}</span>
            </div>
          ) : null}

          {props.subtitle && <h4>{props.subtitle}</h4>}

          {props.location && props.location.length ? (
            <div className="margin-bottom location-details-wrap">
              <div className="location-icon">
                {props.icon && props.icon == "virtual" ? (
                  <div className="svg-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="29" fill="none">
                    <path d="M31.688 21.125h-12.34c-.051 1.016-.762 1.625-1.676 1.625h-3.047c-.965 0-1.727-.863-1.676-1.625H.813c-.458 0-.813.406-.813.813v.812C0 24.578 1.422 26 3.25 26h26a3.24 3.24 0 0 0 3.25-3.25v-.813c0-.406-.406-.812-.813-.812ZM29.25 2.437C29.25 1.117 28.133 0 26.812 0H5.688A2.438 2.438 0 0 0 3.25 2.438V19.5h26V2.437ZM26 16.25H6.5v-13H26v13Z" fill={props => props.theme.color.uiPrimary} />
                    <path d="M16.012 9.75c1.296 0 2.393-1.12 2.393-2.5 0-1.354-1.097-2.5-2.393-2.5-1.32 0-2.392 1.146-2.392 2.5 0 1.38 1.072 2.5 2.393 2.5Zm1.197.833h-.025a.448.448 0 0 0-.175.052 4.413 4.413 0 0 1-1.021.157c-.275 0-.723-.079-.997-.157a.448.448 0 0 0-.175-.052h-.025c-1.52 0-2.791 1.329-2.791 2.917 0 .026 0 .026.025.026 0 .677.523 1.224 1.196 1.224h5.583c.648 0 1.171-.547 1.196-1.224V13.5c0-1.588-1.271-2.917-2.791-2.917Z" fill={props => props.theme.color.uiPrimary} />
                    </svg>
                  </div>
                ) : null }

                {props.icon && props.icon == "physical" ? (
                  <div className="svg-wrap">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="29" fill="none">
                    <path d="M10.816 23.156c-.609-.71-1.066-2.031-1.066-2.945v-1.473c0-1.37.914-3.097 2.133-3.86l.355-1.066h-1.066a.587.587 0 0 1-.61-.609v-2.031c0-.305.254-.61.61-.61h2.031c.152.051.356.153.457.254.863-1.168 2.742-2.285 4.215-2.539V1.625C17.875.762 17.113 0 16.25 0H1.625C.711 0 0 .762 0 1.625v23.563c0 .457.355.812.813.812h10.41c-.254-.355-.407-1.016-.407-1.422v-1.422Zm-.254-18.484c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031c0 .356-.305.61-.61.61h-2.031a.587.587 0 0 1-.61-.61V4.672Zm-3.25 15.031c0 .356-.304.61-.609.61H4.672a.587.587 0 0 1-.61-.61v-2.031c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031Zm0-6.5c0 .356-.304.61-.609.61H4.672a.587.587 0 0 1-.61-.61v-2.031c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031Zm0-6.5c0 .356-.304.61-.609.61H4.672a.587.587 0 0 1-.61-.61V4.672c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031Zm23.36 9.344-.203-.559L29.555 13c-.711-1.777-2.844-3.25-4.723-3.25H18.993c-1.88 0-4.012 1.473-4.723 3.25l-.864 2.488-.254.559c-1.015.406-1.777 1.625-1.777 2.691v1.473c0 .762.457 1.777 1.066 2.234v2.133c0 .813.66 1.422 1.473 1.422h1.422c.812 0 1.473-.61 1.473-1.422V22.75h10.207v1.828c0 .813.66 1.422 1.472 1.422h1.422c.813 0 1.473-.61 1.473-1.422v-2.133c.61-.457 1.066-1.472 1.117-2.234v-1.473c-.05-1.066-.813-2.285-1.828-2.691Zm-13.66-1.625c.304-.813 1.117-1.422 2.031-1.422h5.738c.914 0 1.727.61 2.032 1.422l.66 1.828h-11.07l.609-1.828Zm-1.778 6.297c-.761 0-1.218-.457-1.218-1.219 0-.71.457-1.219 1.218-1.219.711 0 1.829 1.117 1.829 1.828 0 .762-1.118.61-1.829.61Zm13.407 0c-.762 0-1.828.152-1.828-.61 0-.71 1.066-1.828 1.828-1.828.71 0 1.218.508 1.218 1.219 0 .762-.507 1.219-1.218 1.219Z" fill={props => props.theme.color.uiPrimary} />
                    </svg>
                  </div>
                ): null}
              </div>
              <div className="location-details-text-wrap">
                {Array.isArray(props.location) &&
                  props.location.map((text, i) => <p className="location-details has-no-margin__top has-no-margin__bottom" key={i}>{text}</p>)}

              </div>
            </div>
          ) : null}
      </div>
      <div className="buttons">
        {props.primarybutton && (
          <div>
            <a
              className="c-button c-button__full c-button__blue"
              href={props.primarybutton.url}
            >
              {props.primarybutton.url &&
              props.primarybutton.url.startsWith("tel:") ? (
                <>
                  <span className="a11y-hidden">Phone number </span>
                  <i className="fa-solid fa-phone"></i>
                </>
              ) : null}
              <span>{props.primarybutton.text}</span>
            </a>
          </div>
          
        )}
        {props.fee && (
          <strong className="fee">
            <i className="fa-light fa-circle-dollar"></i>
            <span>{props.fee}</span>
          </strong>
        )}
        
      </div>
    </div>
    {props.summary  ? (
      <div class="lower-wrapper">
        <div className={`margin-bottom`}>
          <p className="is-small has-no-margin__top has-no-margin__bottom">{props.summary}</p>
        </div>
      </div>
    ) : null}
  </Card>
  )
}

export default EventCardForPrint;
