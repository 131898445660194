import styled from "@emotion/styled";

const TextInput = styled.input`
  width: 100%;
  padding: 0 11px;
  height: 40px;
  border: 1px solid ${props => props.theme.color.greyDark};
  border-radius: 3px;
  background-color: #fff;
  box-shadow: ${props => props.theme.boxShadow};
  &.error {
    border-color: red;
  }
`;

export default TextInput;
