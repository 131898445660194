import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const LocationCard = props => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [triggerClicked, setTriggerClicked] = React.useState(false);
  const dialogRef = React.useRef();

  React.useEffect(() => {
    if (dialogOpen) {
      dialogRef?.current?.show();
    } else {
      dialogRef?.current?.close();
    }
  }, [dialogOpen, triggerClicked]);

  const handleDialogOpen = () => {
    setDialogOpen(() => true);
  };

  const handleDialogClose = event => {
    if (event !== "click" && triggerClicked) return;
    setDialogOpen(() => false);
  };

  const imgFadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`;

  const Card = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.color.greyDark};

    ${props => props.theme.breakpoint.tablet} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    h3 {
      font-size: 1.25rem;
      line-height: 1.2;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .card--container {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 10px;

      ${props => props.theme.breakpoint.tablet} {
        padding: 20px 25px;
      }
    }
    .card--content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      ${props => props.theme.breakpoint.tablet} {
        width: calc(100% - 235px);
      }
    }

    .card--buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: start;
      gap: 7px;
      ${props => props.theme.breakpoint.tablet} {
        flex-direction: column;
        width: 235px;
      }
    }

    .c-button {
      padding-inline: 12px;
      margin: 0;
      ${props => props.theme.breakpoint.tablet} {
        width: 100%;
        display: block;
      }
      ${props => props.theme.breakpoint.mobileonly} {
        svg {
          display: none;
        }
      }
    }

    .card--capacity {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: ${props => props.theme.color.grey};
      border-top: 1px solid ${props => props.theme.color.greyDark};
      padding-block: 8px;
      gap: 8px;
    }

    .card--status {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      font-weight: bold;
      margin: 0;

      &-icons {
        margin-right: 8px;
      }
      &-icons svg {
        margin-right: 0.25rem;
        ${props => props.theme.breakpoint.mobileonly} {
          &:nth-of-type(2),
          &:nth-of-type(3) {
            display: none;
          }
        }
      }

      &-link {
        font-size: 12px;
        text-decoration: underline;
      }

      &-updated {
        font-size: 12px;
      }
    }

    .card--fax {
      font-size: 14px;
    }

    button.card--status {
      appearance: none;
      text-align: start;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      display: inline-block;
      position: relative;
      span {
        &:hover,
        &:focus-visible {
          text-decoration: underline;
        }
      }
    }

    .card--dialog {
      border: none;
      border-radius: var(--border-radius);
      box-shadow: -3px -3px 15px 0px rgba(0, 0, 0, 0.1);
      font-size: 14px;
      &[open] {
        bottom: calc(100% + .25rem);
        right: auto;
        width: 200px;
        left: 0;
        ${props => props.theme.breakpoint.tablet} {
          left: calc(100% - 1rem);
        }
      }
    }

    .link-action i + span {
      margin-left: 0.5em;
    }

    .link-action i {
      width: 0.75em;
    }
  `;

  const capacityIcons = number => {
    var numbers = [];
    while (number > 0) {
      numbers.push(number);
      number--;
    }

    return numbers;
  };

  return (
    <Card className="card">
      <div className="card--content card--container">
        {props.title && <h3>{props.title}</h3>}
        {props.additionalinfo && (
          <div
            class="margin-bottom c-media-card__alert"
            dangerouslySetInnerHTML={{ __html: props.AdditionalInformation }}
          ></div>
        )}
        {props.location && props.location.length ? (
          <div className="card--location">
            {Array.isArray(props.location) &&
              props.location.map((text, i) => <div key={i}>{text}</div>)}
          </div>
        ) : null}

        {props.directionslink && (
          <div className="card--directions">
            <a
              className="link-action link-action__blue text-transform__none"
              rel="noreferrer"
              target={props.directionslink.target || "_self"}
              href={props.directionslink.url}
            >
              <i className="fa-solid fa-location-dot fa-w-12"></i>
              <span>{props.directionslink.text}</span>
            </a>
          </div>
        )}

        {props.faxnumber && <b className="card--fax">Fax: {props.faxnumber}</b>}
      </div>
      <div className="card--buttons card--container">
        {props.primarybutton && (
          <a className="c-button c-button__blue" href={props.primarybutton.url}>
            {props.primarybutton.url &&
            props.primarybutton.url.startsWith("tel:") ? (
              <>
                <span className="a11y-hidden">Phone number </span>
                <i className="fa-solid fa-phone"></i>
              </>
            ) : null}
            <span>{props.primarybutton.text}</span>
          </a>
        )}
        {props.secondarybutton && (
          <a
            className="c-button c-button__secondary"
            target={props.secondarybutton.target || "_self"}
            href={props.secondarybutton.url}
          >
            {props.secondarybutton.text}
          </a>
        )}
      </div>

      {props.capacitysummary && (
        <div className="card--capacity card--container">
          {props.capacitysummary.timestampformatted !== null &&
          props.capacitysummary.timestampformatted !== undefined ? (
            <button
              className="card--status"
              onClick={() => {
                if (dialogOpen && triggerClicked) {
                  setTriggerClicked(() => false);
                  handleDialogClose("click");
                } else {
                  handleDialogOpen("click");
                  setTriggerClicked(() => true);
                }
              }}
              onMouseEnter={() => {
                handleDialogOpen("mouseenter");
              }}
              onMouseLeave={() => {
                handleDialogClose("mouseleave");
              }}
            >
              {props.capacitysummary.icon !== null &&
                props.capacitysummary.iconnumber !== null && (
                  <span className="card--status-icons">
                    {capacityIcons(props.capacitysummary.iconnumber).map(
                      idx => (
                        <i
                          key={`fai${idx}`}
                          className={`fa-solid fa-${props.capacitysummary.icon}`}
                          style={{ color: props.capacitysummary.iconcolor }}
                        ></i>
                      )
                    )}
                  </span>
                )}
              {props.capacitysummary.capacitystatustext && (
                <span>
                  {props.capacitysummary.capacitystatustext}{" "}
                  <i className="fa-light fa-info-circle"></i>
                </span>
              )}
              <dialog
                id={`dialog_${props.id}`}
                ref={dialogRef}
                className="card--dialog"
              >
                {props.capacitysummary.lastupdatedtext}{" "}
                <time dateTime={props.capacitysummary.timestampiso}>
                  {props.capacitysummary.timestampformatted}
                </time>
              </dialog>
            </button>
          ) : (
            <div className="card--status">
              {props.capacitysummary.icon !== null &&
                props.capacitysummary.iconnumber !== null && (
                  <span className="card--status-icons">
                    {capacityIcons(props.capacitysummary.iconnumber).map(
                      idx => (
                        <i
                          key={`fai${idx}`}
                          className={`fa-solid fa-${props.capacitysummary.icon}`}
                          style={{ color: props.capacitysummary.iconcolor }}
                          role="presentation"
                        ></i>
                      )
                    )}
                  </span>
                )}
              {props.capacitysummary.capacitystatustext && (
                <span>{props.capacitysummary.capacitystatustext}</span>
              )}
            </div>
          )}
          {props.capacitysummary.morelocationstext !== null &&
            props.capacitysummary.morelocationsurl !== null && (
              <a
                className="card--status-link"
                href={props.capacitysummary.morelocationsurl}
              >
                {props.capacitysummary.morelocationstext}
              </a>
            )}
        </div>
      )}
    </Card>
  );
};

export default LocationCard;
