import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  min-height: 244px;
  background-color: ${props => props.theme.color.grey};
  text-align: center;
  border-radius: 4px;
  border-bottom: 7px solid ${props => props.theme.color.uiPrimary};

  p {
    text-align: center;
    &.title {
      font-size: 20px;
      font-weight: 700;
    }
    &.desc {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 50px;
    }
  }
  a {
    cursor: pointer;
    background-color: ${props => props.theme.color.uiPrimary};
    width: 30px;
    height: 25px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    padding: 15px 25px;

   &:hover,&:focus {
      background-color: ${props => props.theme.color.uiPrimaryActive} !important;
      border-color: ${props => props.theme.color.uiPrimaryActive} !important;
      background-size: auto !important;
      background-image: none !important;
    }

    &:focus {
      outline: grey dotted 2px;
    }
  }
`;

const ContactUs = ({props}) => {
  return (
    <Container>
      <p className="title">{props.title}</p>
      <p className="desc">{props.description}</p>
      <p className="link"><a
        href={props.button.url}
        target={props.button.target}
      >
        {props.button.text}
      </a></p>
    </Container>
  );
};
export default ContactUs;
