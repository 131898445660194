import React, { lazy, Suspense } from "react";
import { LoadingIcon } from "../../components";

const NavSearchContainer = lazy(() => import("./NavSearchContainer"));

const NavSearchContainerWithSuspense = props => {
  return (
    <Suspense fallback={<LoadingIcon />}>
      <NavSearchContainer {...props} />
    </Suspense>
  );
};

export default NavSearchContainerWithSuspense;
