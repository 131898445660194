import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-grow: 0;
  flex-basis: auto;
`;

const ClearAllFilters = ({ clearHandler, activeFilters }) => {
  const handleClearAllFilters = () => keys.forEach(key => clearHandler(key));
  const keys = Object.keys(activeFilters).filter(
    filt => activeFilters[filt].size
  );

  const UI = (
    <Container>
      <button
        className="c-button c-button__action"
        onClick={handleClearAllFilters}
        type="button"
      >
        clear all filters
      </button>
    </Container>
  );

  return keys.length ? UI : null;
};

export default ClearAllFilters;
