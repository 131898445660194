import React, { memo } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import TopDoctor from "../TopDoctor";

// const CardHasImage2 = memo(props => {});

const CardHasImageNew = props => {
  const imgFadeIn = keyframes`
  from {
   opacity:0;
  }
  to {
   opacity:1;
  }
`;

  const Card = styled.div`
    display: block;

    border: 1px solid ${props => props.theme.color.greyDark};
    box-shadow: ${props => props.theme.boxShadow};
    ${props => props.theme.breakpoint.mobileonly} {
      /* border-left: none;
      border-right: none; */
    }
    margin-bottom: 20px;
    padding: 8px;
    ${props => props.theme.breakpoint.tablet} {
      padding: 0;
    }
    .margin-bottom {
      margin-bottom: 10px;
    }

    &.flex {
      display: flex;
      flex-wrap: wrap;
      ${props => props.theme.breakpoint.tablet} {
        flex-wrap: nowrap;
      }
    }
    .media {
      /* height:207.66px;
      height:202.66px; */
      width: 21%;
      flex: 0 0 21%;
       padding: 0 8px 8px 0;
      /* padding-right: 8px;
      padding-bottom:8px;
      padding-left:0;
      padding-top:100%; */
      position:relative;
      ${props => props.theme.breakpoint.tablet} {
        padding: 8px 0 8px 8px;
        /* padding-top:calc(100% + 8px);
        padding-right:0;
        padding-bottom:8px;
        padding-left:8px; */
      }

      .responsive-card-image{
        width:100%;
        /* padding-top:100%; */
        position:relative;
        height:100%;
        background:${props => props.theme.color.grey};
      }
      .standard-image{
        /* background:${props => props.theme.color.grey};
        width:100%;
        height:auto; */
      }
      img {
        /* max-width: 100%;
        position:absolute;
        top:0;
        left:0; */
        width:100%;
        height:100%;
        object-fit:cover;
      }
      ${props => props.theme.breakpoint.tablet} {
        /* width: 200px; */
        flex: 0 1 27%;
      }
      /* img {
        opacity:0;
        animation:fadeIn 300ms ease forwards;
      } */
      img{
        animation: ${imgFadeIn} 1000ms forwards;
      }
    }
    .text {
      padding: 25px 25px 10px 25px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 35%;

      display: ${props.variant === "global" ? "flex" : "block"};
      .flex-right {
        display: ${props.variant === "global" ? "flex" : "block"};
        flex-grow: ${props.variant === "global" ? "1" : "0"};
        flex-direction: ${props.variant === "global" ? "column" : "row"};
        align-items: ${props.variant === "global" ? "flex-end" : "flex-start"};
      }
      flex-direction: column;
      h3,
      h4 {
        margin-top: 0;
        margin-bottom: 13px;
      }

      /* h4{
      margin-bottom:10px;
    } */
      h3 + h4 {
        margin-top: -13px;
      }
      h3{
        font-size: 1.25rem;
        line-height: 1.2;
      }
      h4 {
        line-height: 1.2;
        font-size: 1.125rem;
      }

      /* ${props => props.theme.breakpoint.mobileonly} { */
        padding: 0;
        margin-bottom: 1rem;
        ${props => props.theme.breakpoint.mobileonly} {
        h3 {
          margin-top: 0.5em;
        }
       }
      ${props => props.theme.breakpoint.tablet} {
        /* padding: 20px 9px 20px 25px; */
        padding: ${
          props.variant === "global"
            ? "20px 25px 20px 25px"
            : "20px 9px 20px 25px"
        };
      }
    .multiday {
      display: flex;
      flex-direction: column;
      align:  center;
      justify-content: flex-start;
    }
    .multiday p {
      margin: .5em 0 1em 0;
      color: ${props => props.theme.color.uiSecondary};
      font-size: 14px;
      font-family: 'Gotham Bold';
      letter-spacing: -.12px;
      line-height: 1.15;
      text-transform: uppercase;
    }
    .multiday h3 {
        margin-bottom: 0;
      }

      .link-action {
        & > span {
          color: ${props => props.theme.color.uiPrimary};
        }
      }
    }
    .summary-link-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        margin: 0;
        padding: 0;
      }
      a {
        font-size: 13px;
      }
      span.inpatient-specialty {
      font-size: 70%;
      font-weight: bolder;
      margin-top: 10px;
      display: block;
      text-transform: uppercase;
    }

    span.black-thin {
      font-size: rem-calc(16px);
      text-decoration: none;
      font-size: 14px;
      letter-spacing: -0.12px;
      line-height: 1.15;
      text-decoration: none;
      position: relative;
    }
    }
    .fee {
      margin-right: 1rem;
      font-size: 14px;
      color: ${props => props.theme.color.alert};
    }
    .buttons {

      /* ${props => props.theme.breakpoint.mobileonly} {
        padding:0;
      } */
      padding:0;
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      ${props => props.theme.breakpoint.tablet} {
        padding: 20px 0;
        /* padding: 20px 0; */
        text-align: center;
        flex-direction: column;
        width: 235px;
      }
      .link3 {
        width: 100%;
        flex-shrink: 0;
        margin: auto 0 0 0;
        font-family: ${props => props.theme.fonts.fontBold};
        font-size:14px;
        /* color: ${props => props.theme.color.textColorSecondary};
        font-family: ${props => props.theme.fonts.fontBold}; */
        /* font-size:14px; */
      }

      div {
        margin-right: 15px;

        ${props => props.theme.breakpoint.tablet} {
          margin-right: 0;
          padding: 0 25px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .c-button {
        ${props => props.theme.breakpoint.mobileonly} {

          svg {
            display: none;
          }
        }
        padding: 13px 0;
        padding: 12px 17px;
        text-align: center;
        text-decoration: none;
        margin-bottom: 7px !important;
        /* ${props => props.theme.breakpoint.tablet} { */
          margin-bottom: 7px;
        /* } */
      }
    }
    .is-small {
        font-size: 14px;
        line-height: line-height: 14px;
    }
  `;

  let test;
  if (props.SecondaryButtonTarget == "_blank") {
    test = "noreferrer"
  }

  return (
    <Card className={props.showImage && props.image ? "flex" : null}>
      {props.showImage && props.image &&
        <div className="media">
          {" "}
          <div
            className={
              props.variant === "doctor"
                ? "standard-image"
                : "responsive-card-image"
            }
          >
            <img src={props.image} alt={props.title} />
          </div>
        </div>
      }
      <div className="text">
        {props.title && <h3>{props.title}</h3>}
        {props.tagsList && props.tagsList.length ? (
          Array.isArray(props.tagsList) &&
            props.tagsList.map((link, i) => (
              <a key={i} className="c-category-link defer-to-span margin-bottom" href={link.url}><span>{link.text}</span></a>
            ))
        ) : null}

        { props.topdoctor && (
          <TopDoctor topdoctor={props.topdoctor} />
        )}

        { props.ratingssummary && (
              <div className="c-ratings">
              <div className="c-ratings-stars">
                {Array.from(Array(props.ratingssummary.fullstarcount), (e, i) => {
                  return <span key={i} className="c-ratings-star is-full"><i className="fa-solid fa-star"></i></span>
                })}
                {Array.from(Array(props.ratingssummary.halfstarcount), (e, i) => {
                  return <span key={i} className="c-ratings-star is-half"><i className="fa-solid fa-star-half-stroke"></i></span>
                })}
                {Array.from(Array(props.ratingssummary.emptystarcount), (e, i) => {
                  return <span key={i} className="c-ratings-star is-empty"><i className="fa-regular fa-star"></i></span>
                })} <span className="ratings-rating">{props.ratingssummary.rating}<span className="sr-only"> stars</span></span>
              </div>

              <span className="ratings-summary"><a href={props.ratingssummary.url}>{props.ratingssummary.ratingcount} Ratings, {props.ratingssummary.commentcount} Comments</a></span>
            </div>
            )}
        {props.subtitle && <h4>{props.subtitle}</h4>}
        {props.summary && props.summary.length ? (
          <div className="margin-bottom test2">
            <p className="is-small has-no-margin__top has-no-margin__bottom">{props.summary}</p>
          </div>
        ) : null}
        {props.additionalLink && (
          <div>
        <a className=""
            target={props.additionalLink.target || "_self"}
            href={props.additionalLink.url}>
        <span>{props.additionalLink.text}</span>
      </a>
      </div>)}
      {props.link.text && props.link.url ? (
        <div className="flex-right">
          <a className="link-action"
            rel={test}
            target={props.link.target || "_self"}
            href={props.link.url}
            ><span>{props.link.text}</span>
            <i className="fa-light fa-chevron-right"></i>
            </a>
        </div>
      ) : null}
      </div>

    </Card>
  );
};



export default memo(CardHasImageNew);
