import React, { lazy, Suspense } from "react";
import { LoadingIcon } from "../../components";
// import DoctorSearchContainer from './DoctorSearchContainer';

const DoctorSearchContainer = lazy(() => import("./DoctorSearchContainer"));

const DoctorSearchContainerWithSuspense = props => {
  return (
    <Suspense fallback={<LoadingIcon />}>
      <DoctorSearchContainer {...props} />
    </Suspense>
  );
};

export default DoctorSearchContainerWithSuspense;
