const postalCodeLookup = ({
  setGeoLocation,
  setCleanedZipcode,
  setZipcode,
  setErrorGeolocation
}) => {
  var head = document.getElementsByTagName("head")[0],
    script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyDZJyatQOMsKV6WP9703CrHb1QCsyRl3P0";
  head.appendChild(script);
  script.onload = function() {
    if (navigator.geolocation) {
      var fallback = setTimeout(function() {
        fail("10 seconds expired");
      }, 10000);

      navigator.geolocation.getCurrentPosition(
        function(pos) {
          clearTimeout(fallback);

          var point = new google.maps.LatLng(
            pos.coords.latitude,
            pos.coords.longitude
          );
          new google.maps.Geocoder().geocode({ latLng: point }, function(
            res,
            status
          ) {
            if (
              status == google.maps.GeocoderStatus.OK &&
              typeof res[0] !== "undefined"
            ) {
              var zip = res[0].formatted_address.match(/,\s\w{2}\s(\d{5})/);
              if (zip) {
                setGeoLocation(zip[1]);
                setCleanedZipcode(zip[1]);
                setZipcode(zip[1]);
              } else {
                setErrorGeolocation("Unable to look-up postal code");
                fail("Unable to look-up zip code");
              }
            } else {
              setErrorGeolocation("Unable to look-up geolocation");
              fail("Unable to look-up geolocation");
            }
          });
        },
        function(err) {
          fail(err.message);
        }
      );
    } else {
    }
    function fail(err) {
      console.error("err", err);
      // a.value("Try Again.");
    }
  };
};

export default postalCodeLookup;
