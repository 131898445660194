import styled from "@emotion/styled";

const ScreenReaderText = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

export default ScreenReaderText;
