import React from "react";

const EventCard = ({
  fee,
  date,
  time,
  icon,
  flags,
  title,
  hasfee,
  summary,
  rsvplink,
  location,
  icalexport,
  documentid,
  virtuallink,
  isodatetime,
  onlinesignup,
  directionslink,
  resourceStrings = {
    // Fallback JIC we don't get anything back from the API.
    cost: "Cost:",
    free: "Free",
    phone: "Phone number",
    registrationOptions: "Registration Options",
  },
}) => {
  const isVirtual = icon === "virtual";

  const hasSummary = summary !== "";
  const hasLocation = Array.isArray(location) && location.length > 0;
  const registrationClosed = flags.some((f) => f.type === "closed");

  return (
    <article
      className="event-card"
      id={`event-${documentid}`}
      itemScope
      itemType="https://schema.org/Event"
    >
      {title && (
        <h3 className="event-card__title" itemProp="name">
          {title}
        </h3>
      )}

      <div className="event-card__flags-date">
        <EventFlags flags={flags} />
        <time itemProp="startDate" content={isodatetime} dateTime={isodatetime}>
          {date} {time && `| ${time}`}
        </time>
      </div>

      {hasLocation && (
        <div
          className="event-card__location"
          itemProp="location"
          itemScope
          itemType={`https://schema.org/${isVirtual ? "VirtualLocation" : "Place"}`}
        >
          <div className="event-card__location-icon">
            {isVirtual ? <VirtualIcon /> : <PhysicalIcon />}
          </div>

          <div className="event-card__location-text">
            <address>
              {location.map((loc, i) => (
                <span key={`${loc}-${i}`}>{loc}</span>
              ))}
            </address>

            <div className="event-card__location-links">
              <Link {...directionslink} icon="location-dot" />
              <Link {...virtuallink} icon="video" />
            </div>
          </div>
        </div>
      )}

      {hasSummary && (
        <p itemProp="description" className="event-card__summary">
          {summary}
        </p>
      )}

      <div className="event-card__registration">
        {!registrationClosed && <h4>{resourceStrings.registrationOptions}</h4>}

        <div className="event-card__buttons">
          {!registrationClosed && (
            <>
              <Button
                {...rsvplink}
                icon="phone"
                style="blue"
                a11yText={resourceStrings.phonenumber}
              />
              <Button {...onlinesignup} icon="pencil" style="blue" />
            </>
          )}
          <Button {...icalexport} icon="calendar-days" style="secondary" />
        </div>
      </div>

      <div className="event-card__fee" data-has-fee={hasfee}>
        <strong>
          {resourceStrings.cost} {hasfee ? `$${fee}` : resourceStrings.free}
        </strong>
      </div>
    </article>
  );
};

const Link = ({ icon, text, url, target }) => {
  if (url === undefined || url === "") return null;

  return (
    <a
      href={url}
      rel="noreferrer"
      target={target || "_self"}
      className="link-action link-action__blue text-transform__none"
    >
      {icon && <i className={`fa-solid fa-${icon}`}></i>}
      <span>{text}</span>
    </a>
  );
};
const Button = ({ icon, text, url, target, a11yText, style = "blue" }) => {
  if (url === undefined || url === "") return null;

  return (
    <a
      href={url}
      rel="noreferrer"
      target={target || "_self"}
      className={`c-button c-button__${style}`}
    >
      {a11yText && <span className="a11y-hidden">{a11yText}</span>}
      {icon && <i className={`fa-solid fa-${icon}`}></i>}
      {text && <span>{text}</span>}
    </a>
  );
};

const EventFlags = ({ flags }) => {
  const hasFlags = Array.isArray(flags) && flags.length > 0;

  if (!hasFlags) return null;

  return (
    <div className="event-card__flags">
      {flags.map(({ type, text }, i) => (
        <span
          key={`${type}-${i}`}
          className={`event-card__flag event-card__flag--${type}`}
        >
          <span>{text}</span>
          <svg
            role="presentation"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="25"
            viewBox="0 0 20 25"
            fill="none"
          >
            <path
              d="M0 0H18.3096C19.3297 0 19.695 1.34816 18.8145 1.86319L0 12.8676L18.5988 23.1243C19.5038 23.6234 19.1494 25 18.1159 25H0V12.8676V0Z"
              fill="var(--bg)"
            />
          </svg>
        </span>
      ))}
    </div>
  );
};

const VirtualIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="29"
      fill="none"
    >
      <path
        d="M31.688 21.125h-12.34c-.051 1.016-.762 1.625-1.676 1.625h-3.047c-.965 0-1.727-.863-1.676-1.625H.813c-.458 0-.813.406-.813.813v.812C0 24.578 1.422 26 3.25 26h26a3.24 3.24 0 0 0 3.25-3.25v-.813c0-.406-.406-.812-.813-.812ZM29.25 2.437C29.25 1.117 28.133 0 26.812 0H5.688A2.438 2.438 0 0 0 3.25 2.438V19.5h26V2.437ZM26 16.25H6.5v-13H26v13Z"
        fill="var(--color-uiPrimary)"
      />
      <path
        d="M16.012 9.75c1.296 0 2.393-1.12 2.393-2.5 0-1.354-1.097-2.5-2.393-2.5-1.32 0-2.392 1.146-2.392 2.5 0 1.38 1.072 2.5 2.393 2.5Zm1.197.833h-.025a.448.448 0 0 0-.175.052 4.413 4.413 0 0 1-1.021.157c-.275 0-.723-.079-.997-.157a.448.448 0 0 0-.175-.052h-.025c-1.52 0-2.791 1.329-2.791 2.917 0 .026 0 .026.025.026 0 .677.523 1.224 1.196 1.224h5.583c.648 0 1.171-.547 1.196-1.224V13.5c0-1.588-1.271-2.917-2.791-2.917Z"
        fill="var(--color-uiPrimary)"
      />
    </svg>
  );
};

const PhysicalIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="29"
      fill="none"
    >
      <path
        d="M10.816 23.156c-.609-.71-1.066-2.031-1.066-2.945v-1.473c0-1.37.914-3.097 2.133-3.86l.355-1.066h-1.066a.587.587 0 0 1-.61-.609v-2.031c0-.305.254-.61.61-.61h2.031c.152.051.356.153.457.254.863-1.168 2.742-2.285 4.215-2.539V1.625C17.875.762 17.113 0 16.25 0H1.625C.711 0 0 .762 0 1.625v23.563c0 .457.355.812.813.812h10.41c-.254-.355-.407-1.016-.407-1.422v-1.422Zm-.254-18.484c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031c0 .356-.305.61-.61.61h-2.031a.587.587 0 0 1-.61-.61V4.672Zm-3.25 15.031c0 .356-.304.61-.609.61H4.672a.587.587 0 0 1-.61-.61v-2.031c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031Zm0-6.5c0 .356-.304.61-.609.61H4.672a.587.587 0 0 1-.61-.61v-2.031c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031Zm0-6.5c0 .356-.304.61-.609.61H4.672a.587.587 0 0 1-.61-.61V4.672c0-.305.254-.61.61-.61h2.031c.305 0 .61.305.61.61v2.031Zm23.36 9.344-.203-.559L29.555 13c-.711-1.777-2.844-3.25-4.723-3.25H18.993c-1.88 0-4.012 1.473-4.723 3.25l-.864 2.488-.254.559c-1.015.406-1.777 1.625-1.777 2.691v1.473c0 .762.457 1.777 1.066 2.234v2.133c0 .813.66 1.422 1.473 1.422h1.422c.812 0 1.473-.61 1.473-1.422V22.75h10.207v1.828c0 .813.66 1.422 1.472 1.422h1.422c.813 0 1.473-.61 1.473-1.422v-2.133c.61-.457 1.066-1.472 1.117-2.234v-1.473c-.05-1.066-.813-2.285-1.828-2.691Zm-13.66-1.625c.304-.813 1.117-1.422 2.031-1.422h5.738c.914 0 1.727.61 2.032 1.422l.66 1.828h-11.07l.609-1.828Zm-1.778 6.297c-.761 0-1.218-.457-1.218-1.219 0-.71.457-1.219 1.218-1.219.711 0 1.829 1.117 1.829 1.828 0 .762-1.118.61-1.829.61Zm13.407 0c-.762 0-1.828.152-1.828-.61 0-.71 1.066-1.828 1.828-1.828.71 0 1.218.508 1.218 1.219 0 .762-.507 1.219-1.218 1.219Z"
        fill="var(--color-uiPrimary)"
      />
    </svg>
  );
};

export default EventCard;
