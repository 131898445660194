import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Button}  from "../../styledComponents";


export default function ButtonWithToolTip({
    searchbuttonhelptext,
    isdisabled
}) {
    const tooltip = useRef();
    function clicked(e) {
        if (isdisabled) {
            e.preventDefault();  
        } 
    }

    function keypressed (e) {
        if (e.key == 'Escape'){
            hidetooltip(e);
        }
        if (e.key == 'Enter') {
            if (isdisabled) {
                e.preventDefault();;
            }
        }
    }

    function toggleTooltip (e) {
      if (e.type == 'blur' || e.type == 'mouseleave') {
          hidetooltip();
      } else if (e.type != 'keydown') {
          showtooltip();
      }
    }

    function hidetooltip() {
        tooltip.current.setAttribute('aria-hidden', true);
        tooltip.current.style.display = "none";
    }

    function showtooltip() {
        if (isdisabled)
        {
            tooltip.current.setAttribute('aria-hidden', false);
            tooltip.current.style.display = "block";
        }
    }

    return (
        <Fragment>
            <div className="tooltipWrapper">
                <div id="tooltip" role="tooltip" ref={tooltip} className="tooltip" aria-hidden="true">{searchbuttonhelptext}</div>
                <Button type="submit" aria-describedby="tooltip" className={isdisabled ? "isdisabled" : ""} aria-disabled={isdisabled ? "true" : ""    }
                    onClick={(e) => {clicked(e)}}
                    onMouseEnter={(e) => toggleTooltip(e) }
                    onMouseLeave={(e) => toggleTooltip(e) }
                    onFocus={(e) => toggleTooltip(e) }
                    onBlur={(e) => toggleTooltip(e) }
                    onKeyDown={(e) => keypressed(e) }>Search</Button>
                </div>
            </Fragment>
        );
};
