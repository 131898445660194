import React, { useEffect, useState, Fragment } from "react";
import uniqueId from "lodash/uniqueId";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import styled from "@emotion/styled";

import { Button } from "../../styledComponents";

import { ActionLinkWithIcon, DateInput } from "..";
import { populateZipFromGeo } from "../../actions";

import {
  MarginedBox,
  SelectWrapper,
  Select,
  TextInput,
  ScreenReaderText
} from "../../styledComponents";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";

const Form = styled.form`
  display: flex;
  position: relative;
  max-width: 100%;
  justify-content: center;
  @media all and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
  .text-input {
    &.is-wide {
      flex-basis: 190px !important;
    }
    &.is-medium {
      flex-basis: 157px !important;
      @media all and (max-width: 1024px) {
        flex-basis: 151px !important;
      }
    }
    &.no-margin {
      margin-right: 0;
    }
  }
  &.is-stacked {
    flex-direction: column;
    height: auto;
    padding-top: 1rem;
    .text-field {
      margin-bottom: 1rem;
    }
    .text-field.zip {
      margin: 0;
    }
    .link-container {
      // margin-bottom: 1rem;
    }
    .link-container > div {
      margin-top: 0 !important;
    }
    span.text-spacer {
      color: ${props => props.theme.color.black};
      height: auto;
      padding: 0 0 10px 0;
      @media all and (min-width: 1024px) {
        padding: 0 0 1rem 0;
      }
    }
  }
  .c-banner & {
    max-width: 750px;
    margin: 0 auto 40px auto;
    @media all and (min-width: 1024px) {
      flex-wrap: wrap;
    }
    div[role="combobox"]:first-of-type {
      flex: 1 0 100%;
    }
    div[role="combobox"]:nth-of-type(2) {
      flex: 1 0 60%;
    }
    span.text-spacer {
      flex: 1 0 100%;
      justify-content: center;
      padding: 0;
      @media all and (max-width: 1024px) {
        margin: 10px 0;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.color.uiTertiary}!important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #696969 !important;
  }

  label {
    color: #696969;
    z-index: 1;
  }
  input {
    color: #0e0e0e;
    font-weight: 500;
    font-size: 16px;
    &:hover {
      border: 1px solid #000000;
    }
    &:focus {
      outline: 2px solid #3f51b5;
    }
  }
  .MuiInputLabel-formControl {
    transform: translate(15px, 20px) scale(1) !important;
  }
  .MuiInputLabel-shrink {
    transform: translate(17px, 5.5px) scale(0.75) !important;
  }

  .text-field {
    fieldset {
      border-radius: 3px;
      background: #ffffff;
      margin-top: 5px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    input {
      position: relative;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 18px 0 18px 18px;
      z-index: 1;
      box-shadow: rgb(0 0 0 / 9%) 0px 8px 10px -6px;
      @media all and (min-width: 1024px) {
        width: 100%;
      }
    }
  }
  .text-field.zip {
    margin: 15px 0;
    @media all and (min-width: 1024px) {
      margin: 0 0 0 10px;
    }
    input {
      @media all and (min-width: 1024px) {
        width: 100%;
      }
    }
  }

  .zip-location {
    display: flex;
    flex-direction: column;
    &.is-landing {
      .text-field.zip {
        //margin-bottom: 1.75rem;
      }
      //.is-separator {
      //  color: #ffffff;
      //}
      .link-container {
        @media all and (max-width: 1024px) {
          margin-bottom: 1rem;
        }
      }
      button {
        color: #ffffff;
      }
      .text {
        background-image: repeating-linear-gradient(
          0deg,
          #ffffff,
          #ffffff 2px,
          transparent 0,
          transparent 2em
        );
        color: #ffffff;
      }
      .error {
        color: #ffffff;
      }
    }
    .error {
      color: rgb(0, 118, 168);
      margin: 10px 0;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
    }
  }

  button[type="submit"] {
    height: 55px;
    min-height: 55px;
    flex: 1 0 100%;
    margin-top: 15px;
    padding: 0;
  }

  #react-autowhatever-1,
  .jss2,
  .jss12,
  .makeStyles-suggestionsContainerOpen-12,
  .makeStyles-suggestionsContainerOpen-2 {
    max-height: 250px;
    overflow-y: auto;
    background: #ffffff;
    color: #000000 !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    & > div {
      margin-top: 15px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  // A bit of a hack to make sure we're only targeting browsers that support :has()
  #react-autowhatever-1:not(:has(div)) {
    overflow: hidden;
  }
  #react-autowhatever-1:has(div) {
    overflow-y: auto;
  }

  div.jss11[aria-expanded="false"],
  div.makeStyles-container-11[aria-expanded="false"],
  div.jss36[aria-expanded="false"],
  div.makeStyles-container-36[aria-expanded="false"],
  div.jss22[aria-expanded="false"],
  div.makeStyles-container-22[aria-expanded="false"] {
    .seeAll {
      display: none;
    }
  }

  .MuiMenuItem-root {
    white-space: normal;
    //height: 48px;
    a {
      line-height: 1.5 !important;
    }
  }

  .MuiDrawer-modal & {
    // height: 31px;
    width: 240px;
    .text-field input {
      padding: 6px 16px 6px 16px;
      font-size: 12px;
      button {
        font-size: 1rem;
      }
    }
  }
  header & {
    height: 40px;
    width: 317px;
    margin-bottom: 0;

    .text-field input {
      padding: 11px 0 11px 18px;
    }
  }
  .MuiDrawer-modal &,
  header & {
    .text-field fieldset {
      border: 1px solid ${props => props.theme.color.greyDark};
      border-radius: 20px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .text-field fieldset {
        margin-top: -2px;
        margin-bottom: 8px;
      }
      button[type="submit"],
      input {
        top: -6px;
      }
    }
  }
  &.is-stacked {
    .MuiDrawer-modal & {
      width: 100%;
      .text-field {
        height: 40px;
        margin-bottom: 10px;
        input {
          padding: 0 18px;
          height: 40px;
          //font-size: 1rem;
        }
        &.zip {
          margin-bottom: 0;
        }
      }
      .text-field fieldset {
        border-color: rgba(0, 0, 0, 0.23);
        border-radius: 3px;
        height: 40px;
      }
      button[type="submit"] {
        height: 42px;
        color: #ffffff !important;
      }
    }
  }
  button {
    .MuiDrawer-modal & {
      font-size: 14px;
      color: ${props => props.theme.color.uiSecondary};
    }
    header & {
      font-size: 1rem;
      color: ${props => props.theme.color.uiSecondary};
    }
  }
  ul {
    li {
      padding-left: 0;
      margin-bottom: -6px;
    }
  }
  .title {
    text-align: left;
    padding: 14px 0 0 14px;
    font-weight: bold;
    color: ${props => props.theme.color.uiSecondary};
  }
  .seeAll {
    text-align: left;
    margin-left: 15px;
    padding: 15px 0;
  }
  .jss14,
  .jss39,
  .makeStyles-suggestionsList-14 {
    li:last-child {
      &:after {
        content: "";
        height: 1px;
        width: 97%;
        background: ${props => props.theme.color.greyDark};
        display: block;
        margin: 0 15px;
      }
    }
    a.aMenu {
      font-size: 16px;
      font-family: "Gotham Book";
      font-weight: 600;
      color: #000000;

      &:hover {
        background: none;
      }
    }
  }
  ul[role="listbox"] a.aMenu {
    font-size: 16px;
    font-family: "Gotham Book";
    font-weight: 600;
    color: #000000;

    &:hover {
      background: none;
    }
  }
  .text-spacer {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: #ffffff;
    height: 55px;
    font-family: ${props => props.theme.fonts.fontSecondary};
  }
  .event-alert {
    margin-bottom: 1rem;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // [theme.breakpoints.up('md')]: {
    //   display: "flex",
    //   justifyContent: 'center',
    // }
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
  // input: {
  //   marginTop: "-16px"
  // }
  // divider: {
  //   height: theme.spacing(2)
  // }
}));

export default function LocationSearchBox({
  handleZipInput,
  zipcode,
  errorGeoLocation,
  geoEnabled,
  geoLocation,
  setGeoLocation,
  setErrorGeolocation,
  setCleanedZipcode,
  setZipcode,
  setRadius,
  radiusProp,
  radius,
  onsubmithandler
}) {
  const classes = useStyles();
  const [tempZipCode, setTempZipCode] = useState(zipcode);
  const [didMount, setDidMount] = useState(false);
  const [state, setState] = React.useState({
    single: "",
    popper: "",
    docsingle: ""
  });

  const [id] = useState(uniqueId("search-box-unique-"));

  let radiusValues = radiusProp.split(",");
  const options = radiusValues.map(val => (
    <option key={val} value={val}>
      {val}
    </option>
  ));

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (didMount) {
      setTempZipCode(zipcode);
    }
  }, [zipcode]);

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        id={id}
        className="text-field"
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input
          }
        }}
        {...other}
      />
    );
  };

  const updateLocationOptions = e => {
    e.preventDefault();
    setCleanedZipcode(zipcode);
  };

  const geoLocationButtonUI = () => {
    if (!geoEnabled) {
      return null;
    }
    return errorGeoLocation ? (
      <div role="alert" className="event-alert">
        <span className="error">{errorGeoLocation}</span>
      </div>
    ) : (
      <div>
        <div className="link-container">
          <MarginedBox>
            <ActionLinkWithIcon
              text="Use my current location"
              action={() => {
                populateZipFromGeo({
                  geoLocation,
                  setGeoLocation,
                  setCleanedZipcode,
                  setZipcode,
                  setErrorGeolocation
                });
              }}
            >
              <i className="fa-solid fa-location-dot"></i>
            </ActionLinkWithIcon>
          </MarginedBox>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root} data-test="false">
      <Form
        className="is-stacked"
        onKeyPress={e => {
          if (e.method == "Enter") {
            e.preventDefault();
            onsubmithandler();
          }
        }}
        onSubmit={e => {
          e.preventDefault();
          onsubmithandler();
        }}
      >
        <div className="inner ">
          <span>Within</span>
          <SelectWrapper>
            <label className="a11y-hidden" htmlFor="zipcode-ui-radius">
              Choose a radius value
            </label>
            <Select
              id="zipcode-ui-radius"
              value={radius || radiusProp[0]}
              onChange={e => setRadius(e.target.value)}
            >
              {options}
            </Select>
            <i className="fa-light fa-chevron-down fa-w-14 icon"></i>
          </SelectWrapper>
          <span>miles of</span>
          {/* <div className="text-input is-wide no-margin floating-label">
              <label htmlFor="zipcode-ui-zipcode">
              Zip Code
              </label>
              <TextInput
                id="zipcode-ui-zipcode"
                value={zipcode}
                onChange={handleZipInput}
                type="search"
                className="text-field zip"
              />
            </div> */}
          <div className="text-input is-wide no-margin">
            <FormControl className="text-field zip">
              <InputLabel htmlFor="zipcode-ui-zipcode">Zip Code</InputLabel>
              <OutlinedInput
                id="zipcode-ui-zipcode"
                value={zipcode}
                onChange={handleZipInput}
                type="search"
              />
            </FormControl>
          </div>
        </div>
        {geoLocationButtonUI()}
        <Button type="submit">Search</Button>
      </Form>
    </div>
  );
}
