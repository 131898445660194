import React from "react";
import styled from "@emotion/styled";

const GuidedSearchContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 95px -15px 40px -15px;
  ${props => props.theme.breakpoint.mobileonly} {
    flex-wrap: wrap;
  }
  .media-card {
    position: relative;
    flex: 0 1 430px;
    height: 168px;
    margin: 0 15px;
    display: flex;
    flex-wrap: nowrap;
    background-color: ${props => props.theme.color.uiPrimary};
    border: 0;
    max-width: calc(100% - 30px);
    &:last-of-type {
      background-color: ${props => props.theme.color.uiTertiary};
    }
    ${props => props.theme.breakpoint.mobileonly} {
      margin: 0 0 15px 0;
    }
  }
  .media,
  .text {
    /* flex: 1 0 50%; */
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    background-repeat: none;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 19px 19px 19px 17px;
    box-sizing: border-box;
    max-width: 50%;
    ${props => props.theme.breakpoint.tablet} {
      padding: 19px 37px 19px 17px;
    }

    h2 {
      color: #ffffff;
      text-align: left;
      font-size: 24px;
      margin: 0;
    }
  }
  .media {
    padding: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  button {
    background: none;
    border: 0;
    cursor: pointer;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const GuidedSearch = ({ buckets, checkHandler }) => {
  const UI = buckets.map(
    ({ LeftImage, RightImage, Title, FilterCodeName, Filters }) => (
      <div className="media-card" key={Title}>
        <div
          className="text"
          style={{ backgroundImage: `url("${LeftImage}")` }}
        >
          <button
            onClick={e =>
              checkHandler(
                FilterCodeName,
                e.currentTarget.value,
                e.currentTarget.checked
              )
            }
            value={Filters[0].Value}
          >
            <h2>{Title}</h2>
          </button>
        </div>
        <div className="media">
          <img src={RightImage} alt={Title} />
        </div>
      </div>
    )
  );
  return <GuidedSearchContainer>{UI}</GuidedSearchContainer>;
};

export default GuidedSearch;
