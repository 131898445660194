import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
// import useWindowSize from '../../hooks/useWindowSize';

const Form = styled.form`
  font-family: ${props => props.theme.fonts.fontSecondary};
  width: 48px;
  margin-left: auto;
  background: ${props => props.theme.color.grey};
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  .alphabet_radio_wrapper {
    margin: 0;
    order: 2;
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    flex-basis: auto;
    &.disabled {
      opacity: 0.5;
      label {
        color: ${props => props.theme.color.black};
        cursor: default;
      }
    }
    &.letter_all {
      order: 1;
      /* span:first-of-type{
        display:none;
      } */
    }

    label {
      span.explanation_text {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: 0;
        overflow: hidden;
        position: absolute;
        width: 1px;
      }
    }
  }
  .c-banner + section & {
    position: absolute;
    top: -30px;
    margin-top: 0;
    right: -10px;
  }
  ${props => props.theme.breakpoint.laptop} {
    .c-banner + section & {
      top: 0;
      right: 0;
      position: relative;
      margin-top: -2rem;
      padding: 10px 0;
    }
    position: relative;
    &:before {
      content: "";

      position: absolute;
      top: 0;
      /* left: 0; */
      width: 100vw;
      background: ${props => props.theme.color.grey};
      height: 100%;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
    }
    width: 100%;
    flex-direction: row;
    /* position: static; */
    flex-wrap: wrap;

    .alphabet_radio_wrapper {
      margin: 11px 0 5px 0;
      order: 0;
      flex-basis: auto;
      border-left: 1px solid ${props => props.theme.color.greyDarkest};
      &:first-of-type,
      &:last-of-type {
        border: none;
      }

      &.letter_all {
        order: 0;
        flex-basis: 100%;
        label {
          font-size: 12px;
        }
        span.explanation_text {
          clip: rect(auto, auto, auto, auto);
          height: inherit;
          margin: inherit;
          overflow: inherit;
          position: static;
          width: inherit;
        }
      }
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
    opacity: 0;
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      + span {
        box-shadow: 0 0 0 2px ${props => props.theme.color.uiPrimary};
      }
    }
  }
  label {
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    > span {
      padding: 5.5px 8px;
      display: inline-block;
      width: 100%;
    }
    flex-grow: 1;
    justify-content: center;
    text-align: center;
    ${props => props.theme.breakpoint.laptop} {
      flex-grow: 0;
      font-size: 16px;
      > span {
        padding: 5.5px 10px;
        margin-right: 2px;
        margin-left: 2px;
        width: calc(100% - 4px);
      }
    }
    text-transform: uppercase;

    color: ${props => props.theme.color.uiPrimary};
    &.checked {
      background: ${props => props.theme.color.greyDark};
    }
  }
`;

const AlphabetPicker = ({
  setSelectedLetter,
  selectedLetter,
  enabledLetters
}) => {
  const handleOnChange = e => {
    setSelectedLetter(e.target.value);
  };

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current && inputEl.current.focus();
  }, [selectedLetter]);

  return (
    <Form>
      {[..."abcdefghijklmnopqrstuvwxyz", "all"].map(letter => (
        <div
          key={letter}
          className={`alphabet_radio_wrapper letter_${letter}  ${
            enabledLetters.indexOf(letter) > -1 || letter === "all"
              ? null
              : "disabled"
          }`}
        >
          <label className={letter === selectedLetter ? "checked" : null}>
            <input
              name="alphabetPicker"
              type="radio"
              value={letter}
              ref={letter === selectedLetter ? inputEl : null}
              checked={letter === selectedLetter}
              onChange={handleOnChange}
              disabled={
                letter !== "all" && !(enabledLetters.indexOf(letter) > -1)
              }
            />
            <span>
              <span className="explanation_text">show&nbsp;</span>
              {letter}
              <span className="explanation_text">&nbsp;services</span>
            </span>
          </label>
        </div>
      ))}
    </Form>
  );
};

export default AlphabetPicker;
