import React from "react";
const MobileUtilityButton = ({ button }) => {
  
  const baseCssClass = 'c-button c-button__blue c-button__full';
  const lightBlueClass = 'c-button__blueLight';

  let cssClass = button.UseLightColor === true ? `${baseCssClass} ${lightBlueClass}` : baseCssClass;
  
  const target = button.ExternalWindow === true ? '_blank' : '_self';

  const handleClick = e => {
    if ( e.currentTarget.getAttribute('aria-expanded') === 'true') {
      e.currentTarget.setAttribute('aria-expanded', 'false');
    } else {
      e.currentTarget.setAttribute('aria-expanded', 'true');
    }
  };


  return (
    <li>

      {button.SubButtons !== undefined ? (

        <div>
          <button
            id="mobile-nav-main-wait-times-trigger"
            className={cssClass}
            onClick={handleClick}
            aria-expanded="false"
            aria-controls="mobile-nav-main-wait-times"
          >
            {button.ButtonIcon !== undefined && <i className={button?.ButtonIcon} ></i>}
            <span className="text-transform__none">{button.ButtonText}</span>
          </button>

          <ul id="mobile-nav-main-wait-times" aria-labelledby="mobile-nav-main-wait-times-trigger" role="list">
            {button.SubButtons.map((el, i) => (
              <li key={i}>
                <a
                  className='c-button c-button__full c-button__green'
                  href={el.ButtonUrl}
                  target={el.ExternalWindow === true ? '_blank' : '_self'}
                  { ...el.ExternalWindow === true ? {'rel': 'noreferrer'} : {} }
                >
                  {el.ButtonIcon !== undefined && <i className={el?.ButtonIcon} ></i>}
                  <span className="text-transform__none">{el.ButtonText}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>

      ) : (

        <a
          className={cssClass}
          href={button.ButtonUrl}
          target={target}
          { ...button.ExternalWindow === true ? {'rel': 'noreferrer'} : {} }
        >
          {button.ButtonIcon !== undefined && <i className={button?.ButtonIcon} ></i>}
          <span className="text-transform__none">{button.ButtonText}</span>
        </a>

      )}

    </li>
  );

};

export default MobileUtilityButton;
