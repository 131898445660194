import React from "react";
import { DetailsSummary } from "../../components";
import styled from "@emotion/styled";

const MobileLink = ({ link }) => {
  const PaddedDiv = styled.div`
    padding: 10px 0;
  `;
  const { MegaMenu, HasMegaMenu, Target, Title, LinkText, LinkUrl } = link;
  const linkUI = ({ LinkUrl, Target, LinkText }) => (
    <a
      style={{ backgroundImage: "none" }}
      href={LinkUrl}
      target={Target}
    >
      {LinkText}
    </a>
  );

  const parentLink = linkUI(link);

 
  if (MegaMenu) {
    let column1 = null;
    let column2 = null;
    let column3 = null;
    if (MegaMenu.Column1.length) {
      column1 = {
        title: MegaMenu.Column1Title,
        childrenLinks: MegaMenu.Column1.map((el, i) => (
          <PaddedDiv key={i}>{linkUI(el)}</PaddedDiv>
        ))
      }
    }
    if (MegaMenu.Column2.length) {
      column2 = {
        title: MegaMenu.Column2Title,
        childrenLinks: MegaMenu.Column2.map((el, i) => (
          <PaddedDiv key={i}>{linkUI(el)}</PaddedDiv>
        ))
      }
    }
    if (MegaMenu.Column3.length) {
      column3 = {
        title: MegaMenu.Column3Title,
        childrenLinks: MegaMenu.Column3.map((el, i) => (
          <PaddedDiv key={i}>{linkUI(el)}</PaddedDiv>
        ))
      }
    }
    return (
      <DetailsSummary isMobileMenu={true} padding={true} summary={parentLink}>
        {column1 !== null && ( 
          <>
          <div className="mobile-nav-column-header">{column1.title}</div>
          {column1.childrenLinks}
          </>
        )}

        {column2 !== null && ( 
          <>
          <div className="mobile-nav-column-header">{column2.title}</div>
          {column2.childrenLinks}
          </>
        )}

        {column3 !== null && ( 
          <>
          <div className="mobile-nav-column-header">{column3.title}</div>
          {column3.childrenLinks}
          </>
        )}
      </DetailsSummary>
    );
  }
  
  return <PaddedDiv>{parentLink}</PaddedDiv>;

};
export default MobileLink;
