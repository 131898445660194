import React, { useEffect, useState } from "react";
import deburr from "lodash/deburr";
import uniqueId from "lodash/uniqueId";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import styled from "@emotion/styled";
import axios from "axios";

const Form = styled.form`
  display: flex;
  position: relative;
  display: inline-block;
  height: 54px;
  max-width: 100%;
  .c-banner & {
    margin-bottom: 40px;
  }
  .text-field {
    fieldset {
      border-radius: 3px;
      background: #ffffff;
      margin-top: 4px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    input {
      position: relative;
      width: 668px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 18px 0 18px 18px;
      z-index: 1;
    }
  }

  #react-autowhatever-navSearch,
  .jss12,
  .jss172,
  .makeStyles-suggestionsContainerOpen-12 {
    max-height: 250px;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;

    & > div {
      margin-top: 15px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  // A bit of a hack to make sure we're only targeting browsers that support :has()
  #react-autowhatever-navSearch:not(:has(div)) {
    overflow: hidden;
  }
  #react-autowhatever-navSearch:has(div) {
    overflow-y: auto;
  }

  // div.jss2[aria-expanded=false], div.makeStyles-container-2[aria-expanded=false], div.jss11[aria-expanded=false], div.makeStyles-container-11[aria-expanded=false], div.jss36[aria-expanded=false],div.makeStyles-container-36[aria-expanded=false] {
  //   .seeAll {
  //     display: none;
  //   }
  // }

  .MuiMenuItem-root {
    white-space: normal;
    /*height: 48px;*/
    padding: 10px 14px;
  }

  .MuiDrawer-modal & {
    height: 32px;
    width: 240px;
    .text-field input {
      padding: 14px 16px 12px 16px;
      font-size: 12px;
      button {
        font-size: 1rem;
      }
    }
  }
  header & {
    height: 40px;
    width: 317px;
    margin-bottom: 0;

    .text-field input {
      padding: 11px 0 11px 18px;
    }
  }
  .MuiDrawer-modal &,
  header & {
    .text-field fieldset {
      border: 1px solid ${props => props.theme.color.greyDark};
      border-radius: 20px;
      legend {
        display: inline-block;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .text-field fieldset {
        margin-top: -2px;
        margin-bottom: 8px;
      }
      button[type="submit"],
      input {
        top: -6px;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3f51b5 !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #696969 !important;
  }

  label {
    color: #696969;
    z-index: 1;
  }

  .MuiInputLabel-formControl {
    transform: translate(15px, 12px) scale(1) !important;
  }
  .MuiInputLabel-shrink {
    transform: translate(19px, 1.5px) scale(0.75) !important;
  }
  button {
    color: ${props => props.theme.color.uiPrimary};
    cursor: pointer;
    font-size: 1.4rem;
    z-index: 1;
    .MuiDrawer-modal & {
      font-size: 1rem;
      color: ${props => props.theme.color.uiSecondary};
    }
    header & {
      font-size: 1rem;
      color: ${props => props.theme.color.uiSecondary};
    }
  }
  ul {
    li {
      padding-left: 0;
      margin-bottom: -10px;
    }
  }
  .title {
    text-align: left;
    /*padding: 14px 0 0 14px;*/
    padding: 0px 0 0 14px;
    font-weight: bold;
    color: ${props => props.theme.color.uiSecondary};
  }
  .seeAll {
    text-align: left;
    margin-left: 15px;
    padding: 15px 0;
  }
  .jss14,
  .jss39,
  .jss174,
  .makeStyles-suggestionsList-5,
  .makeStyles-suggestion-4,
  .jss4 {
    li:last-child {
      a.aMenu {
        font-size: 14px;
        font-family: "Gotham Book";
        font-weight: 600;
        color: #000000;

        &:hover {
          background: none;
        }
      }

      & > div > a {
        font-size: 11px;
        font-family: "Gotham Book";
        font-weight: 400 !important;
        color: ${props => props.theme.color.uiSecondary} !important;
      }
      &:after {
        content: "";
        height: 1px;
        width: 97%;
        background: ${props => props.theme.color.greyDark};
        display: block;
        margin: 0 15px;
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
  // input: {
  //   marginTop: "-16px"
  // }
  // divider: {
  //   height: theme.spacing(2)
  // }
}));

export default function NavSearchBox({
  onSubmitHandler,
  placeholder,
  searchTerm,
  predictivesearchapi
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: "",
    popper: ""
  });
  const [receivedSearchParms, setSearchParams] = useState(
    new URLSearchParams(location.search)
  );
  const [tempSearchTerm, setTempSearchTerm] = useState("");

  const [id] = useState(uniqueId("search-box-unique-"));

  useEffect(() => {
    setState(prev => {
      const copiedState = Object.assign({}, prev);
      copiedState.single = searchTerm;
      return copiedState;
    });
  }, [searchTerm]);

  const [stateSuggestions, setSuggestions] = React.useState([]);

  const renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    // Remove a11y warning about aria not supported on the role of the element.
    delete other["aria-autocomplete"];

    return (
      <TextField
        fullWidth
        id={id}
        className="text-field"
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input
          },

          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" aria-label="search" type="submit">
                <i className="fa-solid fa-magnifying-glass"></i>
              </IconButton>
            </InputAdornment>
          )
        }}
        {...other}
      />
    );
  };

  function renderSectionTitle(section) {
    return <div className="title">{section.title}</div>;
  }

  function getSectionSuggestions(section) {
    return section.section;
  }

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <a className="aMenu" href={suggestion.url} rel="noreferrer">
          <span>{suggestion.text}</span>
        </a>
      </MenuItem>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <div>
          Press Enter to search <strong>{query}</strong>
        </div>
      </div>
    );
  };

  const getSuggestionValue = suggestion => {
    return suggestion.text;
  };

  const filterData = rawData => {
    return rawData
      .map(section => {
        return {
          title: section.bucketLabel,
          section: section.bucketResults.map(result => {
            return { text: result.text, url: result.url };
          }),
          bucketorder: section.bucketOrder
        };
      })
      .sort(section => section.bucketorder);
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 2) {
      axios.get(predictivesearchapi, { params: { q: value } }).then(res => {
        setSuggestions(filterData(res.data.buckets));
      });
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (suggestion.url) {
      if (event.key == "Enter") {
        event.preventDefault();
        location.href = suggestion.url;
      }
    }
  };

  const handleChange = name => (event, { newValue, method }) => {
    if (method != "enter") {
      setState({
        ...state,
        [name]: newValue
      });
    }
  };

  const autosuggestProps = {
    multiSection: true,
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
    renderSuggestionsContainer,
    renderSectionTitle,
    getSectionSuggestions
  };

  return (
    <div className={classes.root} data-test="false">
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmitHandler(state.single);
        }}
      >
        <Autosuggest
          {...autosuggestProps}
          id="navSearch"
          inputProps={{
            classes,
            id,
            label: placeholder,
            value: state.single,
            onChange: handleChange("single"),
            InputLabelProps: {}
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={({ containerProps, children, query }) => {
            // remove role=listbox from this element as it's not needed and causes a11y warnings.
            delete containerProps.role;

            return (
              <div {...containerProps}>
                {children}
                {/*
                  <div className="seeAll">
                    <a href={`/search-results?q=${query}`} rel='noreferrer'>
                      See all results for <strong>{query}</strong>
                    </a>
                  </div>
                  */}
              </div>
            );
          }}
        />
      </Form>
    </div>
  );
}
