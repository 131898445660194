import React from "react";
import MobileUtilityButton from "../MobileUtilityButton/MobileUtilityButton";

const MobileUtilityButtonBlock = ({ buttons }) => {
  return (
    <ul className="buttons__mobile-utility" role="list">
      {buttons.map((el, i) => (
        <MobileUtilityButton key={i} button={el} />
      ))}
    </ul>
  );
};

export default MobileUtilityButtonBlock;
