import React, { useEffect, useState, Fragment } from "react";
import Chevron from "../../components/Chevron";
import styled from "@emotion/styled";

const Details = styled.div`

  /* margin-bottom: 20px; */

  padding:0;
&.has-border{
  border:2px solid ${props => props.theme.color.grey};
}
&.is-mobile-menu[open],
&.has-background{
  background-color:${props => props.theme.color.grey};
}
&.has-padding{
  >div{
    padding: 24px 20px 6px 12px;
  }
}
&.is-button{
  border-radius:20px;

  &[open]{
    border-radius:0px;

  }
}
&.is-mobile-menu{
  font-family: ${props => props.theme.fonts.fontPrimary};
  .summary-icon-container {
    justify-content:space-between;
    width:100%;
  }
  .summary{
    padding-left:0;
  }
}
.summary{
.chevron__line1, .chevron__line2 {
  stroke-width: 10;
  stroke-linecap: round;
  -webkit-transition: -webkit-transform 0.4s, stroke 0.4s;
  transition: transform 0.2s, stroke 0.2s;
}

.chevron__line1 {
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}
.chevron__line2 {
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}

 .chevron__container {
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  -webkit-transform: translateY(17px);
  transform: translateY(17px);
}
.chevron__line1 {
  stroke: ${props => props.theme.color.uiPrimary};
  /* -webkit-transform: rotate(40deg);
  transform: rotate(40deg); */
}
.chevron__line2 {
  stroke: ${props => props.theme.color.uiPrimary};
  /* -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg); */
}
}
  &[open]{
    border-color: ${props => props.theme.color.uiTertiary};
    &>.summary{
   .chevron__container{
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  -webkit-transform: translateY(-9px);
  transform: translateY(-9px);
}
 .chevron__line1{
  stroke: ${props => props.theme.color.uiPrimary};
  -webkit-transform: rotate(-80deg);
  transform: rotate(-80deg);
}
 .chevron__line2 {
  stroke: ${props => props.theme.color.uiPrimary};
  -webkit-transform: rotate(80deg);
  transform: rotate(80deg);
}
    }
  }
  fieldset {
    border: none;
    padding:0;
    }
    legend{
      position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
    }
    .summary{
      font-family: ${props => props.theme.fonts.fontSecondary};
      position:relative;
      padding:10px 20px 10px 15px;
      user-select:none;
      display:flex;
      .summary-icon-container{
        display:flex;
        flex-wrap:nowrap;
        .icon{
        margin-right:11px;
      }
      }
      &:focus{
        outline:0;
        background-color:${props => props.theme.color.greyDark};
      }
      &:hover{
        background-color:${props => props.theme.color.greyDark};
        cursor:pointer;
      }
      &.disabled{
        pointer-events:none;
      }
    }
    .summary::-webkit-details-marker {
    display: none;
  }

  }`;

  const CollapsibleDetails = styled.details`

  /* margin-bottom: 20px; */

  padding:0;
&.has-border{
  border:2px solid ${props => props.theme.color.grey};
}
&.is-mobile-menu[open],
&.has-background{
  background-color:${props => props.theme.color.grey};
}
&.has-padding{
  >div{
    padding: 24px 20px 6px 12px;
  }
}
&.is-button{
  border-radius:20px;

  &[open]{
    border-radius:0px;

  }
}
&.is-mobile-menu{
  font-family: ${props => props.theme.fonts.fontPrimary};
  .summary-icon-container {
    justify-content:space-between;
    width:100%;
  }
  .summary{
    padding-left:0;
  }
}
.summary{
.chevron__line1, .chevron__line2 {
  stroke-width: 10;
  stroke-linecap: round;
  -webkit-transition: -webkit-transform 0.4s, stroke 0.4s;
  transition: transform 0.2s, stroke 0.2s;
}

.chevron__line1 {
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}
.chevron__line2 {
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}

 .chevron__container {
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  -webkit-transform: translateY(17px);
  transform: translateY(17px);
}
.chevron__line1 {
  stroke: ${props => props.theme.color.uiPrimary};
  /* -webkit-transform: rotate(40deg);
  transform: rotate(40deg); */
}
.chevron__line2 {
  stroke: ${props => props.theme.color.uiPrimary};
  /* -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg); */
}
}
  &[open]{
    border-color: ${props => props.theme.color.uiTertiary};
    &>.summary{
   .chevron__container{
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  -webkit-transform: translateY(-9px);
  transform: translateY(-9px);
}
 .chevron__line1{
  stroke: ${props => props.theme.color.uiPrimary};
  -webkit-transform: rotate(-80deg);
  transform: rotate(-80deg);
}
 .chevron__line2 {
  stroke: ${props => props.theme.color.uiPrimary};
  -webkit-transform: rotate(80deg);
  transform: rotate(80deg);
}
    }
  }
  fieldset {
    border: none;
    padding:0;
    }
    legend{
      position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
    }
    .summary{
      font-family: ${props => props.theme.fonts.fontSecondary};
      position:relative;
      padding:10px 20px 10px 15px;
      user-select:none;
      display:flex;
      .summary-icon-container{
        display:flex;
        flex-wrap:nowrap;
        .icon{
        margin-right:11px;
      }
      }
      &:focus{
        outline:0;
        background-color:${props => props.theme.color.greyDark};
      }
      &:hover{
        background-color:${props => props.theme.color.greyDark};
        cursor:pointer;
      }
      &.disabled{
        pointer-events:none;
      }
    }
    .summary::-webkit-details-marker {
    display: none;
  }

  }`;

const DetailsSummaryNew = props => {
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(true);
  const classNames = [];
  props.border && classNames.push("has-border");
  props.padding && classNames.push("has-padding");
  props.isButton && classNames.push("is-button");
  props.background && classNames.push("has-background");
  props.isMobileMenu && classNames.push("is-mobile-menu");
  classNames.push("has-alt-padding");
  // const windowWidth = props.windowWidth || null;
  // const mediatAttributes = {
  //   open: false,
  //   disabled: { border: "3px solid red" }
  // };
  // mediatAttributes.open = "";
  useEffect(() => {
    if (props.openat && props.windowWidth) {
      setOpen(props.openat <= props.windowWidth);
      setDisabled(props.disableat <= props.windowWidth);
    }
  }, [props.windowWidth]);

  return (
    <Fragment>
      {props.groupsCollapsible ?
      <CollapsibleDetails open={false} className={classNames.join(" ")}>
      <summary className={disabled ? "disabled" : null}>
        <div className="summary-icon-container">
          {props.isMobileMenu ? null : <Chevron />}
          <span>{props.summary}</span>
          {props.isMobileMenu ? <Chevron /> : null}
        </div>
      </summary>
      <div>{props.children}</div>
      </CollapsibleDetails> :
    <Details open={open} className={classNames.join(" ")}>
      <div>{props.children}</div>
    </Details> }
    </Fragment>
  );
};

export default DetailsSummaryNew;
