import styled from "@emotion/styled";

const Button = styled.button`
  color: #fff;
    border: none;
    font-family: ${props => props.theme.fonts.fontBold};
    border-radius: 3px;
    //background-color: ${props => props.theme.color.uiPrimary};
    background-color: ${props => props.theme.color.uiGreenButton};
    //font-size: 18px;
    font-size: 14px;
    padding: 12px 9px;
    text-transform: uppercase;
    transition:background-color:200ms ease;
    cursor:pointer;
    &:hover,
    &:focus{
      //background-color: ${props => props.theme.color.uiPrimaryActive};
      background-color: ${ props => props.theme.color.uiGreenButtonActive};
    }
    &[disabled]{
      cursor:default;
      opacity:.5;
      &:hover,
    &:focus{
      opacity:.5;
    }
    }
`;

export default Button;
