import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const Hamburger = ({ active, onClick }) => {
  const line1_3 = keyframes`
    from{
      stroke-dashoffset: ${active ? "-93.345678921990250901149185097" : "-38"};
    }
    to{
      stroke-dashoffset: ${active ? "-38" : "-93.345678921990250901149185097"};
    }
  `;
  const circle = keyframes`
     from{
      opacity: ${active ? "1" : "0"};
    }
    to{
      opacity: ${active ? "0" : "1"};
    }
  `;
  const line2 = keyframes`
    from{
      stroke-dashoffset: ${active ? "-50" : "-38"};
      stroke-dasharray: ${active ? "0 70" : "24 70"};
    } 
    to{
      stroke-dashoffset: ${active ? "-38" : "-50"};
      stroke-dasharray: ${active ? "24 70" : "0 70"};
    }
    `;

  const Icon = styled.div`
    cursor: pointer;
    border: none;
    padding: 0;
    background: none;
    position: relative;
    width: 1px;
    height: 1px;
    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      [aria-hidden="true"] & {
        z-index: ${!active ? "10000" : "10"};
      }
      width: 75px;
      height: 75px;
      path {
        fill: none;
        stroke: ${props => props.theme.color.uiPrimary};
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-duration: 400ms;
      }
      circle {
        fill: ${props => props.theme.color.grey};
        animation: ${circle} 700ms forwards ease;
      }
      .line--1,
      .line--3 {
        stroke-dasharray: 24 126.64183044433594;
        animation-name: ${line1_3};
      }

      .line--2 {
        animation-name: ${line2};
      }
    }

    &:focus {
      border: 1px solid red;
      outline: 0;
    }
  `;

  return (
    <Icon>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="30" />
        <path className="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
        <path className="line--2" d="M0 50h70" />
        <path className="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
      </svg>
    </Icon>
  );
};

export default Hamburger;
